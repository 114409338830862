function rapportController(AppSettings, $parse, $rootScope, $scope, WS, $window, $compile, NgMap, $sce, $timeout, $anchorScroll, $translate) {
    'ngInject';
    const vm = this;
    vm.stat_assenceur = {};
    vm.infouser = {};
    vm.filter = {};
    vm.filterdate = {};

    vm.search44={};
    vm.search44.gamme_code = '';
    vm.search44.famille_code = '';
    vm.search44.sousfamille_code = '';
    vm.search44.marque_code = '';
    vm.search44.produit_code = '';
    vm.search44.user_code = '';
    vm.search44.date_debut = '';
    vm.search44.date_fin = '';

    vm.filter.user_code = '';
    vm.filter.day = '';
    vm.currentDate = moment().format("DD/MM/YYYY");
    vm.vente_by_month = {};
    vm.vente_by_month.year = moment().format("YYYY");
    vm.getVenteEncoursByCommercial;
    vm.per_page = "10";
    vm.sf_promo_gratuite = {};
    vm.loading_request = false;
    vm.sf_promo_gratuite.date_debut = moment().format("YYYY-MM") + '-01';
    vm.sf_promo_gratuite.date_fin = moment().format("YYYY-MM-DD");

    vm.icons_charts = ['fa fa-area-chart', "fa fa-pie-chart", "fa fa-bar-chart", "fa fa-line-chart"];
    vm.tournee_activites = [];
    vm.total_tournees = [];
    vm.colors = [
        "#696969", "#bada55", "#ff0000", "#ff80ed", "#407294", "#cbcba9", "#5ac18e", "#065535", "#f7347a", "#794044",
        "#008080", "#ffd700", "#ff7373", "#ffa500", "#40e0d0", "#0000ff", "#003366", "#800000", "#800080", "#8a2be2",
        "#00ff00", "#20b2aa", "#66cdaa", "#4ca3dd", "#ff6666", "#ff7f50", "#468499", "#ff00ff", "#008000", "#088da5",
        "#daa520", "#6897bb", "#000080", "#1ABC9C", "#696969", "#bada55", "#ff0000", "#ff80ed", "#407294", "#cbcba9",
        "#008080", "#ffd700", "#ff7373", "#ffa500", "#40e0d0", "#0000ff", "#003366", "#800000", "#800080", "#8a2be2",
        "#00ff00", "#20b2aa", "#66cdaa", "#4ca3dd", "#ff6666", "#ff7f50", "#468499", "#ff00ff", "#008000", "#088da5",
        "#daa520", "#6897bb", "#000080", "#1ABC9C", "#5ac18e", "#065535", "#f7347a", "#794044", "#1ABC9C", "#d5beea",
        "#cd0065", "#87105f", "#cb1890", "#fba494", "#f9a799", "#2d7a8a", "#ffc0cb", "#dd93d6", "#7429ba", "#cbd7fb",
        "#7c5649", "#575f2d", "#6c4b40", "#8e7543", "#4c352d", "#677b8a", "#776b5e", "#233f5c", "#23282a", "#c74b1e",
        "#513032", "#464f54", "#73738e", "#ffc0cb", "#e9eebb", "#eebbe9", "#cbd7fb", "#fba494", "#dd93d6", "#eebbe9",
        "#a7cad0", "#787883", "#235c5c", "#fc7d84",
    ];
    vm.month_names = ["",
        $translate.instant("Janvier"), $translate.instant("Février"), $translate.instant("Mars"),
        $translate.instant("Avril"), $translate.instant("Mai"), $translate.instant("Juin"),
        $translate.instant("Juillet"), $translate.instant("Août"), $translate.instant("Septembre"),
        $translate.instant("Octobre"), $translate.instant("Novembre"), $translate.instant("Décembre"),
    ];

    // jQuery(function ($) {
    //     var id = 'top10';
    //     var btn_id = 'li-'+id
    //     //accordion
    //     //tab
    //         $('#'+btn_id).click();
    //         $('.active').removeClass('active');
    //         $('#'+id).addClass('active');
    //         $('#'+btn_id).addClass('active');
    //         $("html, body").animate({ scrollTop: $('#'+id).offset().top }, 1000);
    // });


    WS.getScache('pays').then(function (response) {
        vm.getpays = response.data;
        vm.center = vm.getpays.latitude + ', ' + vm.getpays.longitude;
        $scope.$apply();
    }, function (error) {
        console.log(error);
    });

    vm.keymaps = AppSettings.keymaps;
    vm.jours = [];
    for (var i = 1; i < 32; i++) {
        var i = (i < 10) ? '0' + i : i;
        vm.jours.push(i);
    }

    vm.mapForRapporttournee = function () {
        $timeout(function () {
            NgMap.getMap({ id: 'mapforrapporttournee' }).then(function (response) {
                google.maps.event.trigger(response, 'resize');
                response.setCenter({ lat: Number(vm.getpays.latitude), lng: Number(vm.getpays.longitude) });
            });
        }, 7000);

    };

    vm.getCommandes = function () {
        WS.get('bl/byGouvernorat/commande')
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byGouvernorat = response.data;
                    $scope.$apply();
                } else { console.log('response status is not 200 line 98 dashboard.js controller'); }
            })
            .then(null, function (error) {
                console.log(error);
            });

    }
    vm.getGouvernorats = function () {
        WS.get('gouvernorats')
            .then(function (response) {
                if (response.status == 200) {
                    vm.gouvernorats = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getChiffreParRegionParMois = function (code, liste, region) {

        if (!liste) return 0;

        for (var i = 0; i < liste.length; i++) {
            if (liste[i]['month'] == code && liste[i]['region'] == region) {
                return liste[i];
            }

        }

    }

    vm.getTotalVente = function (list, code) {
        if (!list) return 0;
        var total = 0;
        for (var i = 0; i < list.length; i++) {
            if (list[i]['user_code'] == code) {
                total = total + Number(list[i]['net_a_payer']);
            }
        }
        return total;
    }
    vm.getTotalCredit = function (list) {
        if (!list) return 0;
        var total = 0;
        for (var i = 0; i < list.length; i++) {
            total = total + Number(list[i]['encours']);
        }
        return total;
    }

    vm.getTotalReglement = function (list, type) {
        if (!list) return 0;
        var total = 0;
        for (var i = 0; i < list.length; i++) {
            total = total + Number(list[i][type]);
        }
        return total;
    }



    vm.getNombreVisiteParClient = function (code, liste) {


        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i]['mois'] == code) {
                return liste[i];
            }

        }
    }

    vm.getAnalyseByDelegations = function (type, gouvernorat, date_debut, date_fin) {
        WS.get('entetecommercial/byDelegation/' + type + '/' + gouvernorat + '/' + date_debut + '/' + date_fin)
            .then(function (response) {
                if (response.status == 200) {
                    vm.byDelegation = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getAnalyseByGouvernorats = function (type, date_debut, date_fin) {
        WS.get('entetecommercial/analyseByGouvernorat/' + type + '/' + date_debut + '/' + date_fin)
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byGouvernorat = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

   

    vm.getAnalyseByZone = function (type, date_debut, date_fin) {
        WS.get('entetecommercial/analyseByZone/' + type + '/' + date_debut + '/' + date_fin)
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_chiffreByZone = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getByRegionByMonth = function () {
        WS.get('bl/byRegionByMonth/bl')
            .then(function (response) {
                if (response.status == 200) {
                    vm.regionbymonth = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getBynbrcientDnParRegion = function () {
        WS.get('rapports/rapportnbrclientdnparregion')
            .then(function (response) {
                if (response.status == 200) {
                    vm.rapportnbrclientdnparregion = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getCommandeByRegionByMonth = function () {
        WS.get('bl/byRegionByMonth/commande')
            .then(function (response) {
                if (response.status == 200) {
                    vm.regionbymonth = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.selectUsers = function () {
        WS.get('users/selectUsers', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectActivites = () => {
        WS.get('activites/selectActivites')
            .then(function (response) {
                vm.activites = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.specialite = '';
    vm.selectSpecialites = () => {
        WS.get('specialites')
            .then(function (response) {
                vm.specialites = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectRegions = () => {
        WS.get('regions/selectRegions')
            .then(function (response) {
                vm.regions = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.selectGouvernorats = () => {
        WS.get('gouvernorats/selectGouvernorats')
            .then(function (response) {
                vm.gouvernorats = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectZones = () => {
        WS.get('zones/selectZones')
            .then(function (response) {
                vm.zones = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectDelegations = function () {
        WS.get('delegations/selectDelegations')
            .then(function (response) {
                if (response.status == 200) {
                    vm.delegations = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.getGouvernorats = function () {
        WS.get('gouvernorats', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.gouvernorats = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getcommercial = function () {
        WS.get('users/commprev', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.commercial = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getCashvanPrevendeur = function () {
        WS.get('users/cashvanprevendeur', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.commercial = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.selectCashvanAndLiv = () => {
        WS.get('users/selectCashvanAndLiv')
            .then(function (response) {
                if (response.status == 200) {
                    vm.commercial = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getComWidthLivzero = function () {
        WS.get('users/getComWidthLivzero')
            .then(function (response) {
                if (response.status == 200) {
                    vm.commercial = response.data;
                    vm.selectusers = response.data;
                    console.log('**********************************');
                    console.log(vm.selectusers);
                    console.log('**********************************');
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }



    vm.getcentermaps = function () {
        WS.getScache('pays').then(function (response) {
            vm.getpays = response.data;
            vm.center = vm.getpays.latitude + ', ' + vm.getpays.longitude;
            vm.zoom = AppSettings.zoomalgerie;
            $scope.$apply();
        }, function (error) {
            console.log(error);
        });
    };

    vm.getClientInactifs = function (page = 1, per_page = 10) {
       
        WS.getData('clients/FindClientsinactifs',
            {
                page, per_page,
                periode: vm.filter.date,
                type: vm.filter.operation,
                routing: vm.filter.routing,
                categorie: vm.filter.categorie,
                annee: vm.filter.annee,
                gouvernorat: vm.filter.gouvernorat

            })
            .then(function (response) {
                if (response.status == 200) {
                    vm.inactifs = response.data.data;
                    vm.current_page = response.data.current_page;
                    vm.previous = vm.current_page - 1;
                    vm.next = vm.current_page + 1;
                    vm.from = response.data.form;
                    vm.last_page = response.data.last_page;
                    vm.next_page_url = response.data.next_page_url;
                    vm.per_page = Number(response.data.per_page);
                    vm.prev_page_url = response.data.prev_page_url;
                    vm.to = response.data.to;
                    vm.total = response.data.total;
                    vm.paginate = [];
                    for (var i = 1; i <= vm.last_page; i++) {
                        vm.paginate.push(i);
                    }

                    // calculate center map
                    var center_lat = 0;
                    var center_lng = 0;
                    var total_pos = 0;
                    _.each(vm.inactifs, element => {
                        if (check_lat_lon(element.latitude, element.longitude)) {
                            total_pos++;
                            center_lat += Number(element.latitude);
                            center_lng += Number(element.longitude);

                        }
                    });

                    if (total_pos > 0) {
                        center_lat = center_lat / total_pos;
                        center_lng = center_lng / total_pos;

                        /**
                 * MAPS
                 */
                        vm.selectmapsclients = angular.copy(vm.inactifs);
                        vm.centerroutes = angular.copy(center_lat + ', ' + center_lng);
                        vm.zoomRouting = 10;
                        $('#container-Client').empty();
                        $('#container-Client').html('<div style="width: 100%; height: 600px" id="mapContainerClients"></div>');

                        var mymap = L.map('mapContainerClients').setView([center_lat, center_lng], vm.zoomRouting);


                        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
                            foo: 'bar',
                            attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
                        })
                            .addTo(mymap);


                        vm.selectmapsclients.forEach(function (element, index) {
                            // Creates a red marker with the coffee icon
                            var redMarker = L.ExtraMarkers.icon({
                                icon: 'fa-home',
                                markerColor: 'blue',
                                shape: 'circle', //'circle', 'square', 'star', or 'penta'
                                prefix: 'fa'
                            });

                            var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);
                            var info = $compile(`
                                         <div id="bodyContent" style="width=300px;">
                                              <p class="text-center">
                                                <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                    Modifier
                                                </a>
                                                <b> | </b>
                                                <a ui-sref="app.clients.historiques({code : ${element.code}})" target="_blank">
                                                  Historique
                                                </a>
                                              </p>
                                              <b>Client</b> : ${element.nomclient}</a></br>
                                              <b>Magasin</b> : ${element.magasin}</a></br>
                                              <b>Code</b> : ${element.code}</a></br>
                                              <b>Adresse</b> : ${element.adresse_facturation}</br>
                                              <b>Route</b> : ${element.routing_code}</br>
                                              <b>Mobile</b> : ${element.mobile}</br>
                                            </div>
                                        `)($scope);
                            var popup = L.popup({
                                maxWidth: 600,
                                minWidth: 200
                            }).setContent(info[0])
                            marker.bindPopup(popup);

                        });
                    }

                    $scope.$apply();
                    // $('.table_inactifs').DataTable({
                    //     "language": DatatableLang
                    // });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.exportclientsinactifs = function (periode, type,routing,categorie,gouvernorat,annee) {

        WS.get('clients/inactifs/' + periode + '/' + type+ '/' + routing+ '/' + categorie+ '/' + gouvernorat+ '/' + annee).then(
            function (response) {
                var count = response.data.length;

                var filename = 'Clients Inactifs depuis-' + periode;
                // var filename = response.data[count-1].type+'-'+response.data[count-1].date_visite;

                var file = {
                    sheetid: 'Clients-Inactifs depuis-' + periode,
                    headers: true,
                    caption: {
                        title: 'Clients-Inactifs depuis-' + periode,
                        style: 'font-size: 50px; color:blue;'
                    },
                    column: {
                        style: 'font-size:20px;background:#d1d1d1;'
                    },
                    columns: [

                        { columnid: 'code', title: 'Code' },
                        { columnid: 'nomclient', title: 'Nom' },
                        { columnid: 'magasin', title: 'Magasin' },
                        { columnid: 'adresse_facturation', title: 'Adresse ' },
                        { columnid: 'mobile', title: 'Telephone ' },
                        { columnid: 'routing_code', title: 'Routing' },
                        { columnid: 'categorie', title: 'Categorie' },
                        { columnid: 'gouvernorat', title: 'Gouvernorat' },
                        { columnid: 'annee', title: 'Anne' },
                        { columnid: 'longitude', title: 'Longitude' },
                        { columnid: 'latitude', title: 'Latitude' },
                        { columnid: 'date_creation', title: 'Date creation' },
                        { columnid: 'last_visite', title: 'Derniere visite' },
                        { columnid: 'total_visite', title: 'Total visite' },
                        { columnid: 'total_ca', title: 'CA' }

                    ],
                    row: {
                        style: function (sheet, row, rowidx) {
                            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                        }
                    }
                };
                alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
            },
            function (error) {
                console.log(error);
            }
        );
    };
    

    vm.ck_lat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
    vm.ck_lon = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;

    function check_lat_lon(lat, lon) {
        var validLat = vm.ck_lat.test(lat);
        var validLon = vm.ck_lon.test(lon);
        if (validLat && validLon) {
            return true;
        } else {
            return false;
        }
    }


    vm.getcommercialByCode = function (code) {
        var url = 'users/query/code="';
        url += code + '"';

        WS.get(url, vm.access)

            .then(function (response) {
                if (response.status == 200) {
                    vm.commerciallist = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getAttributeValue = function (code, liste, attribute, value) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] == code && liste[i]['client_code'] == value) {
                return liste[i];
            }
        }
    }

    vm.getAttributeValueforRapVent = function (code, liste, attribute, value) {
        //console.log(liste);
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] == code && liste[i]['code'] == value) {
                return liste[i];
            }
        }
    }


    vm.getAttributeValueforDN = function (code, liste, attribute, value) {
        if (!liste) return;

        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] == code && liste[i]['code'] == value) {
                return liste[i];
            }
        }
    }

    vm.getTotalClient = function (liste, value) {
        var tot = 0;
        if (!liste) return tot;

        for (var i = 0; i < liste.length; i++) {

            if (liste[i]['code'] == value) {
                tot = parseInt(liste[i]['nbrclient']) + parseInt(tot);
            }

        }
        return tot;
    }

    vm.getAttributeOneValue = function (code, liste, attribute) {
        if (!liste) return;

        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] == code) {
                return liste[i];
            }
        }
    }

    vm.getAttributeOneValuedate = function (code, liste, attribute, date) {
        if (!liste) return;

        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] == code && liste[i]['date'] == date) {
                return liste[i];
            }
        }
    }


    vm.getZones = function () {
        WS.get('zones')
            .then(function (response) {
                if (response.status == 200) {
                    vm.zones = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getSecteur = function () {
        WS.get('secteurs')
            .then(function (response) {
                if (response.status == 200) {
                    vm.secteurs = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getRegions = function () {
        WS.get('regions')
            .then(function (response) {
                if (response.status == 200) {
                    vm.regions = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getGammes = function () {
        WS.get('gammes')
            .then(function (response) {
                vm.gammes = response.data;
                vm.selectgammes = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getMarque = function () {
        WS.get('marques')
            .then(function (response) {
                vm.marques = response.data;
                vm.selectmarques = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getFournisseurs = function () {
        WS.get('fournisseurs')
            .then(function (response) {
                vm.fournisseurs = response.data;
                vm.selectfournisseurs = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getAllproduits = function () {
        WS.get('produits/names')
            .then(function (response) {
                vm.produitsnames = response.data;
                vm.selectproduitsnames = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getFamilles = function () {
        WS.get('familles')
            .then(function (response) {
                vm.familles = response.data;
                vm.selectfamilles = response.data;

                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getSousfamilles = function () {
        WS.get('sousfamilles')
            .then(function (response) {
                vm.sousfamilles = response.data;
                vm.selectsousfamilles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.getRapportVenteclient = function (details, entete) {

        var url = 'rapports/venteclient' + '/' + entete + '/' + details.date_debut + '/' + details.date_fin + '/' + details.user_code + '/' + details.type;
        WS.get(url, vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.rapportventeclient = response.data;
                    var resdate = [];
                    var j = 0;
                    angular.forEach(response.data, function (element, index) {
                        if (resdate.length >= 0) {
                            angular.forEach(resdate, function (elementcheck, indexcheck) {
                                if (element.date == elementcheck.date && index != indexcheck) {
                                    j++;
                                }
                            });

                            if (j == 0) {
                                resdate.push({ date: element.date });
                            } else {
                                j = 0;
                            }
                        }


                    });
                    vm.resdate = resdate;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }



    vm.getClientsFiltred = function () {

        var url = 'clients/secteur';

        url += '/' + vm.secteur;

        WS.get(url)
            .then(function (response) {
                if (response.status == 200) {
                    vm.clients = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getClientsFiltredCommercial = function (code) {
        var url = 'clients/commercial';
        url += '/' + code;
        WS.get(url)
            .then(function (response) {
                if (response.status == 200) {
                    vm.clients = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.GetRapportVentesoufamilleparclient = function (details, type) {
        var data = {
            type,// bl or commande
            date_debut : details.date_debut,
            date_fin : details.date_fin,
            commercial : details.commercial,
            categorie : details.categorie,
            value : details.type //qte or quantite or chiffre
        }
        //var url = 'rapports/rapportventesousfamille' + '/' + type + '/' + details.date_debut + '/' + details.date_fin + '/' + details.commercial + '/' + details.type;
        var url = 'rapports/rapportventesousfamille';
        WS.getData(url, data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.rapportventesousfamille = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getSuiviVenteClientParType = function (details) {
        var url = 'rapports/getVenteParClientParType' + '/' + details.date_debut + '/' + details.date_fin + '/' + details.route+ '/' + details.choix + '/' + details.fieldvalue;
        WS.get(url, vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.suivi_venteparclientpartype = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getRapportVentesanscommercial = function (details, type) {
        var url = 'rapports/rapportvente' + '/' + type + '/' + details.date_debut + '/' + details.date_fin + '/' + details.choix + '/' + details.fieldvalue;
        WS.get(url, vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.rapportventesanscommercial = response.data;
                    vm.typeofv = details.type;
                    vm.choixofv = details.choix;
                    console.log("vm.rapportventesanscommercial", vm.rapportventesanscommercial);

                    /**
                     * total by users
                     */
                    var users_total = _.groupBy(vm.rapportventesanscommercial, function (obj) { return obj.user_code; });

                    var res_total_users = {};
                    _.each(users_total, (element, index) => {
                        var total = _.reduce(element, function (memo, num) { return memo + num.total; }, 0);
                        var qte = _.reduce(element, function (memo, num) { return memo + num.qte; }, 0);
                        var carton = _.reduce(element, function (memo, num) { return memo + num.carton; }, 0);
                        res_total_users[index] = { total, qte, carton };
                    });
                    vm.res_total_users = res_total_users;
                    console.log('res_total_users', res_total_users);

                    /**
                     * total by categories
                     */

                    var categories_total = _.groupBy(vm.rapportventesanscommercial, function (obj) { return obj.code; });

                    var res_total_categories = {};
                    _.each(categories_total, (element, index) => {
                        var total = _.reduce(element, function (memo, num) { return memo + num.total; }, 0);
                        var qte = _.reduce(element, function (memo, num) { return memo + num.qte; }, 0);
                        var carton = _.reduce(element, function (memo, num) { return memo + num.carton; }, 0);
                        res_total_categories[index] = { total, qte, carton };
                    });
                    vm.res_total_categories = res_total_categories;
                    console.log('res_total_categories', res_total_categories);
                    $scope.$apply();

                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.getBeneficeParPeriode = function (suivi_cls) {
        WS.getData('rapports/getbeneficeParPeriode', suivi_cls)
            .then(response => {
                vm.beneficeParPeriode = response.data;

                vm.choixType = suivi_cls.choix;
                var action_user = response.data;
                var action_categorie = response.data;

                vm.beneficebyuser = _.groupBy(action_user, function (value) { return value.user_code; });
                vm.beneficebycategorie = _.groupBy(action_categorie, function (value) { return value.code; });

                /**
                 * group by user
                 */
                var totaluser = [];
                _.each(vm.beneficebyuser, (element, index) => {
                    var obj = {};
                    obj.user_code = element[0].user_code;
                    obj.beneficetotal = _.reduce(element, function (memo, num) { return memo + num.benefice; }, 0);
                    totaluser.push(obj);
                });

                vm.totalbeneficebyuser = totaluser;

                /**
                 * groupBy categorie (quelque soit : (gamme, famille , sousfamille, produits, marque)
                 */

                var totalcategorie = [];
                _.each(vm.beneficebycategorie, (element, index) => {

                    var obj = {};
                    obj.categorie = element[0].code;
                    obj.beneficetotal = _.reduce(element, function (memo, num) { return memo + num.benefice; }, 0);

                    totalcategorie.push(obj);
                });

                vm.totalbeneficebycategorie = totalcategorie;


                $scope.$apply();
            });
    };

    vm.table2excel = function (tableId) {
        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;
        var title = "rapport_par_periode-" + moment().format('YYYY-MM-DD');

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);

    };

    vm.exporttableHtml = function (tableId) {

        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;
        var title = 'Rapport';

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);

    };

    vm.GetDnGammeVendeur = function (details, type) {
        var url = 'rapports/dngammevendeur' + '/' + type + '/' + details.date_debut + '/' + details.date_fin + '/' + details.choix;
        WS.get(url, vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.dngammevendeur = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    /*$(document).on('click', '.pin', function() {
            $(this).siblings().removeClass('selected');
            $(this).addClass('selected');
            var id =  $(this).find('.pin').data('id') ;
            $('tbody tr').removeClass('selected');
            $('tr[data-id='+ id +']').addClass('selected');

            $('html, body').animate({
                scrollTop: $('tr[data-id='+ id +']').offset().top - 100
            }, 1000);
        });*/

    vm.findClientByMaps = function (id) {

        angular.element(document.getElementById('client-' + id)).addClass('markerGreen');
        $timeout(function () {
            angular.element(document.getElementById('client-' + id)).removeClass('markerGreen');
            $timeout(function () {
                angular.element(document.getElementById('client-' + id)).addClass('markerGreen');
                $timeout(function () {
                    angular.element(document.getElementById('client-' + id)).removeClass('markerGreen');
                    $('#client-' + id).scrollTop();
                }, 300);
            }, 300);
        }, 200);




    }
    vm.selektMarker = function (id) {
        angular.element($('.pin-' + id)).addClass('markerRed');
        $timeout(function () {
            angular.element($('.pin-' + id)).removeClass('markerRed');
            $timeout(function () {
                angular.element($('.pin-' + id)).addClass('markerRed');
                $timeout(function () {
                    angular.element($('.pin-' + id)).removeClass('markerRed');
                }, 300);
            }, 300);
        }, 200);
    }

    vm.getrapportvisites = function (code, date) {
        WS.getScache('visites/byCommercial' + '/' + code + '/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.rapportvisites = response.data.visites;
                    vm.centerrapvisite = response.data.center.center;
                    vm.centerrapmaps = response.data.center;
                    vm.kilometragerap = response.data.kilometrage;
                    /**
                     * maps openstreetmaps
                     */
                    $('#maps_rapport_tournee').empty();
                    $('#maps_rapport_tournee').append('<div style="width: 100%; height: 580px" id="maps_rapport_tournee_panel"></div>');
                    var mymap = L.map('maps_rapport_tournee_panel').setView([vm.centerrapmaps.latitude, vm.centerrapmaps.longitude], 12);
                    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                        attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
                    }).addTo(mymap);
                    // Creates a red marker with the coffee icon
                    var markerClNonVisiteIcon = L.ExtraMarkers.icon({
                        icon: 'fa-home',
                        markerColor: 'red',
                        shape: 'circle', //'circle', 'square', 'star', or 'penta'
                        prefix: 'fa'
                    });
                    var MarkerClientVisiteIcon = L.ExtraMarkers.icon({
                        icon: 'fa-home',
                        markerColor: 'blue',
                        shape: 'circle', //'circle', 'square', 'star', or 'penta'
                        prefix: 'fa'
                    });

                    var GreenMarkerClientVisiteIcon = L.ExtraMarkers.icon({
                        icon: 'fa-home',
                        markerColor: 'green',
                        shape: 'circle', //'circle', 'square', 'star', or 'penta'
                        prefix: 'fa'
                    });

                    _.each(vm.rapportvisites, (element, index) => {
                        // position visite

                        var markerClientVisite = L.marker([element.latitude, element.longitude], { icon: MarkerClientVisiteIcon }).addTo(mymap);
                        // markerClientVisite.bindTooltip(element.code, { permanent: true, direction: 'top' });

                        var infoClientVisite = $compile(`
                                                 <div id="bodyContent" style="width=400px;">
                                                      <div class="text-center">
                                                        <a ui-sref="app.clients.historiques({code : '${element.codeClient}'})" target="_blank">
                                                          Historique
                                                        </a>
                                                      </div>
                                                      <div><b>Code</b> : ${element.codeClient}</a></div>
                                                      <div><b>Client</b> : ${element.nomclient}</a></div>
                                                      <div><b>Magasin</b> : ${element.magasin}</a></div>
                                                      <div><b>Temps de visite</b> : ${element.difference}</div>
                                                      <div><b>Distance de visite</b> : ${element.distance}</div>
                                                      <div><b>Commentaire: </b> : ${element.commentaire}</a></div>
                                                </div>
                                                `)($scope);
                        var popupClientVisite = L.popup({
                            maxWidth: 600,
                            minWidth: 300
                        }).setContent(infoClientVisite[0])
                        markerClientVisite.bindPopup(popupClientVisite);

                        // position client visite
                        // var markerClNonVisite = L.marker([element.c_latitude, element.c_longitude], { icon: GreenMarkerClientVisiteIcon }).addTo(mymap);
                        // var infoNonVisite = $compile(`
                        //                          <div id="bodyContent" style="width=400px;">
                        //                               <div class="text-center">
                        //                                 <a ui-sref="app.clients.historiques({code : '${element.codeClient}'})" target="_blank">
                        //                                   Historique
                        //                                 </a>
                        //                               </div>
                        //                               <div><b>Code</b> : ${element.codeClient}</a></div>
                        //                               <div><b>Client</b> : ${element.nomclient}</a></div>
                        //                               <div><b>Magasin</b> : ${element.magasin}</a></div>
                        //                               <div><b>Temps de visite</b> : ${element.difference}</div>
                        //                               <div><b>Distance de visite</b> : ${element.distance}</div>
                        //                               <div><b>Commentaire: </b> : ${element.commentaire}</a></div>
                        //                         </div>
                        //                         `)($scope);
                        // var popupNonVisite = L.popup({
                        //     maxWidth: 600,
                        //     minWidth: 300
                        // }).setContent(infoNonVisite[0])
                        // markerClNonVisite.bindPopup(popupNonVisite);
                    });
                    //console.log('vm.tournees_non_traite', vm.tournees_non_traite);
                    $scope.$watchCollection(
                        "vm.tournees_non_traite",
                        function (newValue, oldValue) {

                            _.each(newValue, (element, index) => {
                                // position client non visite
                                var markerClNonVisite = L.marker([element.latitude, element.longitude], { icon: markerClNonVisiteIcon }).addTo(mymap);
                                var infoNonVisite = $compile(`
                                                         <div id="bodyContent" style="width=400px;">
                                                              <div class="text-center">
                                                                <a ui-sref="app.clients.historiques({code : '${element.codeClient}'})" target="_blank">
                                                                  Historique
                                                                </a>
                                                              </div>
                                                              <div><b>Code</b> : ${element.client_code}</a></div>
                                                              <div><b>Client</b> : ${element.nomclient}</a></div>
                                                              <div><b>Magasin</b> : ${element.magasin}</a></div>
                                                        </div>
                                                        `)($scope);
                                var popupNonVisite = L.popup({
                                    maxWidth: 600,
                                    minWidth: 300
                                }).setContent(infoNonVisite[0])
                                markerClNonVisite.bindPopup(popupNonVisite);
                            });

                        }
                    );

                    setTimeout(function () {
                        mymap.invalidateSize();
                    }, 80);
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.exportgetrapportvisites = function (code, date) {

        WS.get('visites/exportbyCommercial' + '/' + code + '/' + date).then(
            function (response) {
                var count = response.data.length;

                //var filename = 'Rapport-Tournee-'+moment().format("DD-MM-YYYY_HH:mm:ss");
                var filename = response.data[count - 1].type + '-' + response.data[count - 1].date_visite;

                var file = {
                    sheetid: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
                    headers: true,
                    caption: {
                        title: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
                        style: 'font-size: 50px; color:blue;'
                    },
                    column: {
                        style: 'font-size:20px;background:#d1d1d1;'
                    },
                    columns: [

                        { columnid: 'vcode', title: 'Numero' },
                        { columnid: 'net_a_payer', title: 'Montant' },
                        { columnid: 'nomclient', title: 'Client' },
                        { columnid: 'heure_debut', title: 'Heure ' },
                        { columnid: 'user', title: 'Commercial' },
                        { columnid: 'date_visite', title: 'Date' },
                        { columnid: 'type', title: 'type' }

                    ],
                    row: {
                        style: function (sheet, row, rowidx) {
                            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                        }
                    }
                };
                response.data.forEach(function (element, index) {
                    response.data[index].net_a_payer = response.data[index].net_a_payer.toString().replace('.', ',');
                });
                alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
            },
            function (error) {
                console.log(error);
            }
        );
    };



    vm.export_rapport_42 = function(tableId, title = 'Rapport suivi Comercial par client') {
        
        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function(s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);

    };

    vm.printPanel = function(panel) {

        var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`<html>
                                    <head>
                                    <title> ${document.title} </title>
                                    <style>
                                        .noprint{display: none;}.text-center{text-align:center}
                                        th{border:1px solid #ddd!important;}
                                        td{border:1px solid #ddd!important;}
                                    </style>
                                `);
        mywindow.document.write('</head><body >');
        mywindow.document.write(document.getElementById(panel).innerHTML);
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    }


    vm.getValueByCodeByListe = function (code, liste) {


        if (!liste) return 0;
        for (var i = 0; i < liste.length; i++) {
            if (liste[i]['client_code'] == code) {
                return liste[i];
            }
        }
        return 0;
    }


    vm.getproduitsAssortiment = function (date_debut, date_fin) {
        WS.get('assortiments/rapports' + '/' + date_debut + '/' + date_fin)
            .then(function (response) {
                if (response.status == 200) {
                    vm.produits_assortiment = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

  
    vm.exportAllVisiteByCommercial = function (code, date) {

        WS.get('visites/exportAllbyCommercial' + '/' + code + '/' + date).then(
            function (response) {
                var count = response.data.length;

                //var filename = 'Rapport-Tournee-'+moment().format("DD-MM-YYYY_HH:mm:ss");
                var filename = response.data[count - 1].type + '-' + response.data[count - 1].date_visite;

                var file = {
                    sheetid: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
                    headers: true,
                    caption: {
                        title: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
                        style: 'font-size: 50px; color:blue;'
                    },
                    column: {
                        style: 'font-size:20px;background:#d1d1d1;'
                    },
                    columns: [

                        { columnid: 'vcode', title: 'Numero' },
                        { columnid: 'net_a_payer', title: 'Montant' },
                        { columnid: 'nomclient', title: 'Client' },
                        { columnid: 'adresse_facturation', title: 'Adresse' },
                        { columnid: 'heure_debut', title: 'Heure ' },
                        { columnid: 'user', title: 'Commercial' },
                        { columnid: 'date_visite', title: 'Date' },
                        { columnid: 'type', title: 'type' },
                        { columnid: 'cause', title: 'Cause' },
                        { columnid: 'commentaire', title: 'Commentaire' }

                    ],
                    row: {
                        style: function (sheet, row, rowidx) {
                            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                        }
                    }
                };

                alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
            },
            function (error) {
                console.log(error);
            }
        );
    };

    vm.exportAllVisiteByCommercialMois = function (code, date) {

        WS.get('visites/exportAllbyCommercialMois' + '/' + code + '/' + date).then(
            function (response) {
                var count = response.data.length;

                //var filename = 'Rapport-Tournee-'+moment().format("DD-MM-YYYY_HH:mm:ss");
                var filename = response.data[count - 1].type + '-' + response.data[count - 1].date_visite;

                var file = {
                    sheetid: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
                    headers: true,
                    caption: {
                        title: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
                        style: 'font-size: 50px; color:blue;'
                    },
                    column: {
                        style: 'font-size:20px;background:#d1d1d1;'
                    },
                    columns: [

                        { columnid: 'vcode', title: 'Numero' },
                        { columnid: 'net_a_payer', title: 'Montant' },
                        { columnid: 'nomclient', title: 'Client' },
                        { columnid: 'adresse_facturation', title: 'Adresse' },
                        { columnid: 'heure_debut', title: 'Heure ' },
                        { columnid: 'user', title: 'Commercial' },
                        { columnid: 'date_visite', title: 'Date' },
                        { columnid: 'type', title: 'type' },
                        { columnid: 'cause', title: 'Cause' },
                        { columnid: 'commentaire', title: 'Commentaire' }

                    ],
                    row: {
                        style: function (sheet, row, rowidx) {
                            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                        }
                    }
                };

                alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
            },
            function (error) {
                console.log(error);
            }
        );
    };


    vm.getmontantttc = function (code, date) {
        WS.get('entetecommercial/ttc' + '/' + code + '/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.montantttc = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getmontant_total_bl_FromEntete = function (code, date) {

        WS.get('bl/distinctmontant' + '/' + code + '/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.montant_total_ttcFromEntete = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getmontant_total_cmd_FromEntete = function (code, date) {

        WS.get('commandes/distinctmontant' + '/' + code + '/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.montant_total_cmd_ttcFromEntete = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getmontant_total_avoir_FromEntete = function (code, date) {

        WS.get('avoir/distinctmontant' + '/' + code + '/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.montant_total_avoir_ttcFromEntete = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getrecouvrementttc = function (code, date) {
        console.log(code + ' /  ' + date);
        WS.get('paiements/ttc' + '/' + code + '/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.recouvrementttc = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.getProduits = function () {
        WS.get('produits')
            .then(function (response) {
                if (response.status == 200) {
                    vm.produits = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.selectProduits = function () {
        WS.get('produits/selectProduit')
            .then(function (response) {
                if (response.status == 200) {
                    vm.produits = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectProduitGamme = function () {
        WS.get('produits/selectProduitGamme')
            .then(function (response) {
                if (response.status == 200) {
                    vm.produits = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.getMoisBydate = function (date) {

        var mydate = new Date(date);

        var tab_mois = new Array("Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre");
        vm.mois = tab_mois[mydate.getMonth()];

        return vm.mois;
    }

    vm.getCAByAttributeValue = function (code, liste, attribute, value) {

        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] == value && liste[i]['code'] == code) {
                //console.log('forclient=', liste[i]);

                return liste[i];
            }


        }
    }

    vm.getCAByAttributeValueNbClient = function (code, liste, attribute, value) {

        if (!liste) return ' 0 ';
        for (var i = 0; i < liste.length; i++) {
            if (liste[i][attribute] == value && liste[i]['code'] == code) {
                return liste[i];
            } else {
                return 0;
            }
        }
        return ' 0 ';
    }


    vm.getValue = function (code, liste, attribute, value) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {
            if (liste[i][attribute] == value) {
                return liste[i];
            }
        }
    };

    vm.getblbyCommercialcode = function (code, date, type) {
        WS.get('bl/byCommercialcode/' + code + '/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.blbyCommercialcode = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getCmdbyCommercialcode = function (code, date, type) {
        WS.get('commandes/byCommercialcode/' + code + '/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.cmdbyCommercialcode = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getRapportVenteProduitParTournee = function (code, date) {
        WS.get('rapports/rapportventeproduitpartournee/' + date + '/' + code)
            .then(function (response) {
                if (response.status == 200) {
                    vm.rapportventeproduitpartournee = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getcmdbyCommercialcode = function (code, date) {
        WS.get('commandes/byCommercialcode' + '/' + code + '/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.blbyCommercialcode = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getKPIBydate = function (date) {
        WS.getScache('bl/getKPIBydate/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byCommercialDate = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.getKPICommandeBydate = function (date) {
        WS.getScache('commandes/getKPICommandeBydate/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.cmd_byCommercialDate = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    WS.get('bl/byCommercial/commande/year')
        .then(function (response) {
            if (response.status == 200) {
                vm.cmd_byCommercialYear = response.data;
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });


    vm.getCreditByCommercialByDate = function (date) {
        WS.get('paiements/creditByCommercialByDate/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.creditByCommercialByDate = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    WS.get('paiements/getetatCredit/date')
        .then(function (response) {
            if (response.status == 200) {

                vm.credittoday = response.data;
                vm.creditTodayForAll = 0;

                _.each(response.data, e => {
                    vm.creditTodayForAll += parseFloat(e.solde);
                })

                $scope.$apply();
            } else { }
        })


    WS.get('visites/byCommercial/count/month')
        .then(function (response) {
            if (response.status == 200) {
                vm.visites_byCommercialCountMonth = response.data;
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });

    WS.get('clients/byCommercial/month')
        .then(function (response) {
            if (response.status == 200) {
                vm.clientsbyCommercialThisMonth = response.data;
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });


    WS.get('paiements/getetatCredit/year')
        .then(function (response) {
            if (response.status == 200) {
                vm.creditthisyear = response.data;


                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });


    WS.get('commandes/byCommercial/count/month')
        .then(function (response) {
            if (response.status == 200) {
                vm.cmd_byCommercialCountMonth = response.data;
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });
    WS.get('commandes/byCommercial/count/year')
        .then(function (response) {
            if (response.status == 200) {
                vm.cmd_byCommercialCountYear = response.data;
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });
    WS.get('commandes/byCommercial/count/date')
        .then(function (response) {
            if (response.status == 200) {
                vm.cmd_byCommercialCountDate = response.data;

                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });


    WS.get('bl/byCommercialcount/month')
        .then(function (response) {
            if (response.status == 200) {
                vm.bl_byCommercialCountMonth = response.data;
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });
    WS.get('bl/byCommercialcount/year')
        .then(function (response) {
            if (response.status == 200) {
                vm.bl_byCommercialCountYear = response.data;
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });


    WS.get('visites/byCommercial/count/year')
        .then(function (response) {
            if (response.status == 200) {
                vm.visites_byCommercialCountYear = response.data;
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });
    WS.get('clients/byCommercial/year')
        .then(function (response) {
            if (response.status == 200) {
                vm.clientsbyCommercialThisYear = response.data;
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });
    WS.get('paiements/getetatCredit/month')
        .then(function (response) {
            if (response.status == 200) {
                vm.creditthismonth = response.data;

                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });

    WS.get('bl/byCommercial/commande/month')
        .then(function (response) {
            if (response.status == 200) {
                vm.cmd_byCommercialMonth = response.data;
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });

    WS.get('bl/byCommercial/commande/year')
        .then(function (response) {
            if (response.status == 200) {
                vm.cmd_byCommercialYear = response.data;
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });




    WS.get('bl/byCommercial/bl/month/withavoir')
        .then(function (response) {
            if (response.status == 200) {
                vm.bl_byCommercialMonth = response.data;
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });






    WS.get('bl/byCommercial/bl/year/withavoir')
        .then(function (response) {
            if (response.status == 200) {
                vm.bl_byCommercialYear = response.data;
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });





    /**/
    /*  WS.get('visites/byCommercial/count/date')
    .then(function(response) {
        if (response.status == 200) {
            vm.visites_byCommercialCountDate = response.data;
            
            $scope.$apply();
        } else {}
    })
    .then(null, function(error) {
        console.log(error);
    });*/



    vm.nb_visite_byCommercialByDate = function (date) {
        //  console.log('visites/byCommercialByDate/' + date + '/' + vm.date_fin);
        WS.get('visites/byCommercialByDate/' + date + '/' + vm.date_fin)
            .then(function (response) {
                if (response.status == 200) {
                    vm.visites_byCommercialCountDate = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.nb_visite_byCommercialByDateJournalier = function (date) {
        //  console.log('visites/byCommercialByDate/' + date + '/' + vm.date_fin);
        WS.get('visites/byCommercialCountByDateJournalier/' + date + '/' + vm.date_fin)
            .then(function (response) {
                if (response.status == 200) {
                    vm.visites_byCommercialCountDate = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.nb_bl_byCommercialByDate = function (date) {
        WS.get('bl/byCommercialcountByDate/' + date + '/' + vm.date_fin)
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byCommercialCountDate = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

    };

    vm.nb_commandes_byCommercialByDate = function (date) {
        WS.get('commandes/byCommercialcountByDate/' + date + '/' + vm.date_fin)
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byCommercialCountDate = response.data;
                    vm.cmd_byCommercialCountDate = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

    };




    vm.getGammeswithproduits = function () {
        WS.get('gammes/produits')
            .then(function (response) {
                if (response.status == 200) {
                    vm.Gammeswithproduits = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getClientsbyCommercialdate = function (date) {
        WS.get('clients/byCommercial/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.clientsbyCommercialdate = response.data;
                    console.log('vm.clientsbyCommercialdate', vm.clientsbyCommercialdate)
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getRapportJournalier = function () {
        var url = 'rapports/journalier';
        url += '/' + vm.date + '/' + vm.date_fin + '/' + vm.type;
        WS.get(url)
            .then(function (response) {
                if (response.status == 200) {
                    vm.journalier = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.getclientsbyCommercialcount = function () {
        var url = 'clients/byCommercial/';
        url += vm.date + '/' + vm.date_fin;
        WS.get(url)
            .then(function (response) {
                if (response.status == 200) {
                    vm.clientsbyCommercialToday = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getRapportJournalierCommande = function () {
        var url = 'rapports/journalierCommande';
        url += '/' + vm.date + '/' + vm.type + '/' + vm.date_fin;
        WS.get(url)
            .then(function (response) {
                if (response.status == 200) {
                    vm.journalier = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.getRapportJournalierSousFamille = function () {

        var url = 'rapports/journaliersousfamille';
        url += '/' + vm.date + '/' + vm.date_fin + '/' + vm.type;

        WS.get(url)
            .then(function (response) {
                if (response.status == 200) {
                    vm.journalier = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getChiffreByFamille = function () {

        WS.getData('rapports/famille', { famille_code: vm.c_famille_code, date_debut: vm.c_date_debut, date_fin: vm.c_date_fin })
            .then(function (response) {
                if (response.status == 200) {
                    vm.ventefamille = response.data;
                    var interval = 0;
                    var jour = 0;
                    var mois = 0;
                    var annee = 0;

                    _.each(vm.ventefamille, element => {
                        interval += (element.filter.total) ? element.filter.total : 0;
                        jour += (element.jour.total) ? element.jour.total : 0;
                        mois += (element.mois.total) ? element.mois.total : 0;
                        annee += (element.annee.total) ? element.annee.total : 0;
                    });
                    vm.total_interval = interval;
                    vm.total_jour = jour;
                    vm.total_mois = mois;
                    vm.total_annee = annee;

                    console.log('vm.ventefamille', vm.ventefamille)
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getventefamille = function (commercial, liste) {
        if (!liste) return;

        for (var i = 0; i < liste.length; i++) {
            if (liste[i]['code'] == commercial.trim()) {
                return liste[i];
            }
        }
    }



    vm.getTotalByProduitandCommercial = function (produit, code, liste) {
        if (!liste) return;

        for (var i = 0; i < liste.length; i++) {

            if (liste[i]['produit_code'] == produit.trim() &&
                liste[i]['user_code'] == code.trim()) {
                return liste[i];
            }
        }
    }

    vm.getTotalByProduitandCommercialSousfamille = function (sousfamille, code, liste) {
        if (!liste) return;

        for (var i = 0; i < liste.length; i++) {

            if (liste[i]['code'] == sousfamille.trim() &&
                liste[i]['user_code'] == code.trim()) {
                return liste[i];
            }
        }
    }

    WS.get('clients/byCommercial/today')
        .then(function (response) {
            if (response.status == 200) {
                vm.clientsbyCommercialToday = response.data;
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });

    vm.getPaiementByCommercial = function (date) {
        WS.get('paiements/getEtatPaiementByCommercial' + '/' + date + '/' + vm.date_fin)
            .then(function (response) {
                if (response.status == 200) {
                    vm.getEtatPaiementByCommercial = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.venteEncoursByCommercial = function (date) {
        WS.get('rapports/getAllDetailsByCommercial' + '/' + date + '/' + vm.date_fin)
            .then(function (response) {
                if (response.status == 200) {
                    vm.getVenteEncoursByCommercial = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getByAttributeValue = function (code, liste, attribute, value) {

        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] == value && liste[i]['code'] == code) {

                return liste[i];
            }


        }
    };

    vm.getByAttributeValueBL = function (code, liste) {

        if (!liste) return;
        if (liste == null) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i]['code'] == code) {

                return liste[i];
            }


        }
    };

    vm.getByAttributeValueCMD = function (code, liste, attribute) {

        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i]['code'] == code) {
                return liste[i];
            }
        }
    }

    vm.getSommeByAttributeValue = function (liste, attribute, value, code) {
        if (!liste) return;
        var total = 0;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] == value) {
                total = parseFloat(total) + parseFloat(liste[i][code]);
            }


        }
        return total;
    }

    vm.getSommeByAttributeValuebl = function (liste, attribute) {

       
        if (!liste) return;
        var total = 0;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] != 'null' && liste[i][attribute] != null) {
                total = parseFloat(total) + parseFloat(liste[i][attribute]);
            }


        }
        return total;
    }
    vm.getSommeByAttributeValueCredit = function (liste, attribute) {

       
        if (!liste) return;
        var total = 0;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] != 'null' && liste[i][attribute] != null) {
                total = parseFloat(total) + parseFloat(liste[i][attribute]);
            }


        }
       
        return total;
    }

    vm.getSommeByAttributeValuecmd = function (liste, attribute) {
        if (!liste) return;
        var total = 0;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] != 'null' && liste[i][attribute] != null) {
                total = parseFloat(total) + parseFloat(liste[i][attribute]);
            }


        }
        return total;
    }

    vm.getSommeBLByAttributeValue = function (liste, attribute, value, code) {
        if (!liste) return;
        var total = 0;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] == value) {
                total = parseFloat(total) + parseFloat(liste[i][code]);
            }

        }
        return total;
    }



    vm.getSommeProduit = function (code, liste, type) {
        if (!liste) return;

        var total = 0;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][type] == code) {
                total = parseFloat(total) + parseFloat(liste[i]['total']);
            }



        }
        return total
    }

    vm.getSommeChiffre = function (code, liste) {
        if (!liste) return;
        var total = 0;
        for (var i = 0; i < liste.length; i++) {
            if (liste[i]['user_code'] == code) {
                total = parseFloat(total) + parseFloat(liste[i]['total']);
            }
        }
        return total;
    }



    vm.getAllSommeChiffre = function (liste, attribute) {
        if (!liste) return;
        var total = 0;
        for (var i = 0; i < liste.length; i++) {


            total = parseFloat(total) + parseFloat(liste[i][attribute]);



        }
        return total
    }
    vm.getAllCreditThisMonth = function (code) {

        WS.get('paiements/getetatCreditbycommercial/' + code)
            .then(function (response) {
                if (response.status == 200) {
                    vm.AllCreditThisMonth = new Array(31).fill(0);

                    response.data.forEach(function (e, index) {
                        var jour = parseInt(e.date.substr(8, 2));

                        vm.AllCreditThisMonth[jour - 1] += parseFloat(Math.abs(e.solde));
                    });

                    $scope.$apply();
                } else { }
            })
    }

    vm.getAnalysebyCommercialcode = function (code) {

        WS.get('entetecommercial/analysesBycommercial' + '/' + code)
            .then(function (response) {
                if (response.status == 200) {





                    vm.ventes_net = new Array(31).fill(0);
                    vm.commandes_net = new Array(31).fill(0);
                    response.data.forEach(function (e, index) {
                        var jour = parseInt(e.date.substr(8, 2));
                        var year = parseInt(e.date.substr(0, 4));

                        var mois = parseInt(e.date.substr(5, 2));
                        if (mois == (new Date()).getMonth() + 1) {

                            if (e.type == 'bl') {


                                vm.ventes_net[jour - 1] += parseFloat(e.net_a_payer);

                            }
                            if (e.type == 'commande') {
                                vm.commandes_net[jour - 1] += parseFloat(e.net_a_payer);

                            }

                        }

                    });

                    vm.lineData = {
                        labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
                        series: [
                            vm.ventes_net,

                            vm.AllCreditThisMonth,

                            vm.commandes_net
                        ]

                    };

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

    }
    // line chart


    vm.lineOptions = {
        height: '200px',
        axisX: {
            labelInterpolationFnc: function (value) {
                return value;
            }
        }
    };

    vm.dngammevendeurGraph = function (type) {

        WS.get('rapports/dngammevendeur/graph/' + type)
            .then(function (response) {
                var resultat = [];
                angular.forEach(response.data, function (element, index) {
                    var chartData = [];
                    var j = 0;
                    chartData.push({
                        "date": new Date(element.date),
                        "value": element.nbrclient,
                        "volume": element.quantite
                    });
                    angular.forEach(response.data, function (elementCompare, indexCompare) {
                        if (elementCompare.libelle == element.libelle && index != indexCompare) {
                            chartData.push({
                                "date": new Date(elementCompare.date),
                                "value": elementCompare.nbrclient,
                                "volume": elementCompare.quantite
                            });
                        };
                    });

                    if (resultat.length >= 0) {
                        angular.forEach(resultat, function (elementResult, indexResult) {
                            var elm = element.libelle.replace(/\s+/g, '-');
                            var elmRes = elementResult.title.replace(/\s+/g, '-');
                            if (elm == elmRes) {
                                j++;
                            }
                        });
                    }
                    if (j == 0) {
                        resultat.push({
                            "title": element.libelle,
                            "fieldMappings": [{
                                "fromField": "value",
                                "toField": "value"
                            }, {
                                "fromField": "volume",
                                "toField": "volume"
                            }],
                            "dataProvider": chartData,
                            "categoryField": "date"
                        });
                    }
                });
                var chart = AmCharts.makeChart("dngammevendeurGraph", {
                    "type": "stock",
                    "theme": "light",
                    "dataSets": resultat,

                    "panels": [{
                        "showCategoryAxis": false,
                        "title": "Value",
                        "percentHeight": 70,
                        "stockGraphs": [{
                            "id": "g1",
                            "valueField": "value",
                            "comparable": true,
                            "compareField": "value",
                            "balloonText": "[[title]]:<b>[[value]]</b>",
                            "compareGraphBalloonText": "[[title]]:<b>[[value]]</b>"
                        }],
                        "stockLegend": {
                            "periodValueTextComparing": "[[percents.value.close]]%",
                            "periodValueTextRegular": "[[value.close]]"
                        }
                    }, {
                        "title": "Volume",
                        "percentHeight": 30,
                        "stockGraphs": [{
                            "valueField": "volume",
                            "type": "column",
                            "showBalloon": false,
                            "fillAlphas": 1
                        }],
                        "stockLegend": {
                            "periodValueTextRegular": "[[value.close]]"
                        }
                    }],

                    "chartScrollbarSettings": {
                        "graph": "g1"
                    },

                    "chartCursorSettings": {
                        "valueBalloonsEnabled": true,
                        "fullWidth": true,
                        "cursorAlpha": 0.1,
                        "valueLineBalloonEnabled": true,
                        "valueLineEnabled": true,
                        "valueLineAlpha": 0.5
                    },

                    "periodSelector": {
                        "position": "left",
                        "periods": [{
                            "period": "MM",
                            "selected": true,
                            "count": 1,
                            "label": "1 month"
                        }, {
                            "period": "YYYY",
                            "count": 1,
                            "label": "1 year"
                        }, {
                            "period": "YTD",
                            "label": "YTD"
                        }, {
                            "period": "MAX",
                            "label": "MAX"
                        }]
                    },

                    "dataSetSelector": {
                        "position": "left"
                    },

                    "export": {
                        "enabled": true
                    }
                });
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.dncommercialclientGraph = function () {

        WS.get('rapports/dncommercialclientGraph')
            .then(function (response) {

                var uniqcommercial = [];
                //remove duplicate item
                angular.forEach(response.data, function (elementuniq, keyuniq) {
                    var cc = 0;
                    if (uniqcommercial.length >= 0) {
                        angular.forEach(uniqcommercial, function (elementuniqCom, keyuniqCom) {
                            if (elementuniq.commercial == elementuniqCom) {
                                cc++;
                            }
                        });
                    }
                    if (cc == 0) {
                        var filtered = elementuniq.commercial;
                        uniqcommercial.push(filtered);
                    }
                });

                var config = [];
                angular.forEach(uniqcommercial, function (elem, key) {
                    config.push({
                        "balloonText": "Vendeur :" + elem + " in [[category]]: [[value]] %",
                        "bullet": "round",
                        "hidden": false,
                        "title": elem,
                        "valueField": elem,
                        "fillAlphas": 0
                    });
                });


                var resultat = [];
                var j = 0;
                angular.forEach(response.data, function (element, key) {
                    if (resultat.length >= 0) {
                        angular.forEach(resultat, function (elementCheck, keyCheck) {
                            if (elementCheck.date == element.date) {
                                j++;
                            }
                        });
                    }

                    if (j == 0) {
                        var item = {};
                        var commercial = element.commercial;
                        item.date = element.date;
                        item[commercial] = element.pcnbrclient;
                        angular.forEach(response.data, function (elementCompare, keyCompare) {
                            if (element.date == elementCompare.date && element.commercial != elementCompare.commercial) {
                                var commercial = elementCompare.commercial;
                                item[commercial] = elementCompare.pcnbrclient;
                            }
                        });
                        resultat.push(item);
                    }
                    j = 0;

                });
                // sort resultat by date
                resultat.sort(function (a, b) {
                    return new Date(a.date) - new Date(b.date);
                });


                vm.dateforth = resultat;
                vm.uniqcommercial = uniqcommercial;
                vm.countclientfortab = response.data;
                /**
                [{
                                        date: "2017-01",
                                        BEN_ALI_SABEUR: "1.01"
                                    }, {
                                        date: "2017-11",
                                        GUITOUNI_KAMEL: "7.89",
                                        BEN_ALI_SABEUR: "2.02"
                                    }]
                */

                var chart = AmCharts.makeChart("dncommercialclientGraph", {
                    "type": "serial",
                    "theme": "light",
                    "legend": {
                        "useGraphSettings": true
                    },
                    "dataProvider": resultat,
                    "valueAxes": [{
                        "integersOnly": true,
                        "maximum": 100,
                        "minimum": 1,
                        "reversed": false,
                        "axisAlpha": 0,
                        "dashLength": 5,
                        "gridCount": 5,
                        "position": "left",
                        // "title": "Taux de Couverture client par vendeur (%)",
                        "title": $translate.instant('Taux de Couverture client par vendeur (%)')
                    }],
                    "startDuration": 0.5,
                    "graphs": config,
                    "chartCursor": {
                        "cursorAlpha": 0,
                        "zoomable": false
                    },
                    "categoryField": "date",
                    "categoryAxis": {
                        "gridPosition": "start",
                        "axisAlpha": 0,
                        "fillAlpha": 0.05,
                        "fillColor": "#000000",
                        "gridAlpha": 0,
                        "position": "top"
                    },
                    "export": {
                        "enabled": true,
                        "position": "bottom-right"
                    }
                });

                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });

    };

    vm.differncetime = function (firsttime, lasttime) {
        var val1 = moment(firsttime, "HH:mm:ss");
        var val2 = moment(lasttime, "HH:mm:ss");
        //console.log(val1,val2);

        var hDiff = val2.diff(val1, 'hours');
        var mDiff = val2.diff(val1, 'minutes');
        var sDiff = val2.diff(val1, 'seconds');

        var secondDuration = sDiff % 60;
        var minuteDuration = mDiff % 60;
        //var minuteDuration = Math.floor(sDiff % 60);
        var hourDuration = Math.floor(mDiff / 60);


        return hourDuration + ':' + minuteDuration + ':' + secondDuration;

        /* var fromDate = moment.utc('2013-03-02 01:30:00');
         var duration = moment.duration({'hour':2, 'minute':70});

         var toDate = moment(fromDate);
         toDate.add(duration);
         console.log(fromDate.format('mmmm DD-MMM-YYYY hh:mm a'));
         console.log(toDate.format('mmmm DD-MMM-YYYY hh:mm a'));
         var hourDiff = toDate.diff(fromDate,'hours');
         var minuteDiff = toDate.diff(fromDate,'minutes');
         var hourDuration = Math.floor(minuteDiff/60);
         var minuteDuration = minuteDiff % 60;

         console.log(hourDuration);
         console.log(minuteDuration);*/
    };

    vm.timework = function (visites) {
        if (visites.length > 0) {
            var time = moment('00:00:00', 'HH:mm:ss');
            visites.forEach(function (element, index) {

                var duration = moment.duration({
                    seconds: moment(element.difference, "HH:mm:ss").format('ss'),
                    minutes: moment(element.difference, "HH:mm:ss").format('mm'),
                    hours: moment(element.difference, "HH:mm:ss").format('HH')
                });
                time.add(duration);
            });
            return time.format('HH:mm:ss');
        }
    };

    /****************************** vente DN par Produits *****************************/

    vm.venteParProduit = function () {
        var vars = '';
        if (vm.filterprod != undefined || vm.filterprod != null) {

            vars += '?';
            if (vm.filterprod.produit) {
                vars += 'produit=' + vm.filterprod.produit + '&';
            }

        } else {
            vars = '';
        }
        WS.get('rapports/venteParProduit' + vars)
            .then(function (response) {
                //vm.venteparproduitregion = response.data;
                var tabs = [];
                if (response.data.length > 0) {
                    response.data.forEach((element, index) => {
                        var i = 0;
                        if (tabs.length > 0) {
                            tabs.forEach((eltab, indextab) => {
                                if (element.produit == eltab.produit) {
                                    eltab[element.month] = element.count;
                                    i = i + 1;
                                    tabs[indextab] = eltab;
                                }
                            });
                            if (i == 0) {
                                var newObject = {};
                                newObject["produit"] = element.produit;
                                newObject[element.month] = element.count;
                                tabs.push(newObject);
                            }
                        } else {
                            var newObject = {};
                            newObject["produit"] = element.produit;
                            newObject[element.month] = element.count;
                            tabs.push(newObject);
                        }
                    });
                }
                vm.venteparproduit = tabs;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
        /*WS.get('rapports/venteParProduit?page='+page)
            .then(function(response) {            
                vm.venteparproduit = response.data;
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });*/
    };

    vm.venteParProduitRegion = function () {
        var vars = '';
        if (vm.filter != undefined || vm.filter != null) {

            vars += '?';
            if (vm.filter.produit) {
                vars += 'produit=' + vm.filter.produit + '&';
            }
            if (vm.filter.region) {
                vars += 'region=' + vm.filter.region + '&';
            }

        } else {
            vars = '';
        }
        WS.get('rapports/venteParProduitRegion' + vars)
            .then(function (response) {
                //vm.venteparproduitregion = response.data;
                var tabs = [];
                if (response.data.length > 0) {
                    response.data.forEach((element, index) => {
                        var i = 0;
                        if (tabs.length > 0) {
                            tabs.forEach((eltab, indextab) => {
                                if (element.produit == eltab.produit && element.region == eltab.region) {
                                    eltab[element.month] = element.count;
                                    i = i + 1;
                                    tabs[indextab] = eltab;
                                }
                            });
                            if (i == 0) {
                                var newObject = {};
                                newObject["produit"] = element.produit;
                                newObject["region"] = element.region;
                                newObject[element.month] = element.count;
                                tabs.push(newObject);
                            }
                        } else {
                            var newObject = {};
                            newObject["produit"] = element.produit;
                            newObject["region"] = element.region;
                            newObject[element.month] = element.count;
                            tabs.push(newObject);
                        }
                    });
                }
                vm.venteparproduitregion = tabs;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });


        /*
        WS.get('rapports/venteParProduitRegion')
            .then(function(response) {
                vm.venteparproduitregion = response.data;
                
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
        */
    };

    vm.first = function () {
        var chart = AmCharts.makeChart("chartdiv", {
            "type": "pie",
            "theme": "light",
            "dataProvider": [{
                "country": "Lithuania",
                "litres": 501.9
            }, {
                "country": "Czech Republic",
                "litres": 301.9
            }, {
                "country": "Belgium",
                "litres": 60
            }, {
                "country": "The Netherlands",
                "litres": 50
            }],
            "valueField": "litres",
            "titleField": "country",
            "balloon": {
                "fixedPosition": true
            },
            "export": {
                "enabled": true
            }
        });
    }
    /****************************** vente DN par Produits *****************************/


    /**
     * Rapport commercial par mois
     */

    vm.filter = {};
    vm.filter.year = moment().format('YYYY');
    vm.filter.month = moment().format('MM');
    //vm.filter.day = moment().format('DD');

    vm.filterByCommercialMonth = function (filter = null) {
        if (filter) {
            vm.filter = filter;
        }
        var url = 'rapports/filterByCommercialMonth';
        if (vm.filter != null) {
            url += '/' + vm.filter.year + '/' + vm.filter.month;
            if (vm.filter.day && vm.filter.day != "") {
                url = url + '/' + vm.filter.day;
            } else {
                url += `/null`;
            }
            if (vm.filter.user_code && vm.filter.user_code != "") {
                url = url + '/' + vm.filter.user_code;
            }
        }

        WS.get(url).then(function (response) {
            vm.kpis = response.data.table;
            vm.kpismonth = response.data.month;
            vm.kpisyear = response.data.year;
            vm.kpisday = response.data.day;

            vm.sumVente = 0;
            vm.sumCredit = 0;
            vm.sumRecouvrement = 0;
            vm.sumRecette = 0;
            vm.sumNbbl = 0;
            vm.sumNbVisite = 0;
            vm.sumNbavoir = 0;
            vm.nbClientVisite = 0;
            vm.totalNbrClientInRoute = 0;
            vm.nbClientNonVisite = 0;
            vm.sumTauxSuc = 0;
            vm.sumNbClient = 0;
            vm.sumnbclsbl = 0;
            for (var i = 0; i < vm.kpis.length; i++) {
                vm.sumVente += Number(vm.kpis[i].vente);
                vm.sumCredit += Number(vm.kpis[i].credit);
                vm.sumCommande += Number(vm.kpis[i].commande);
                vm.sumNbbl += Number(vm.kpis[i].nbbl);
                vm.sumNbavoir += Number(vm.kpis[i].nbavoir);
                vm.sumNbcommande += Number(vm.kpis[i].nbcommande);
                vm.sumNbVisite += Number(vm.kpis[i].nbVisite);
                //  vm.sumTauxSuc += Number(vm.kpis[i].nbbl) * 100 / Number(vm.kpis[i].nbVisite);
                vm.sumNbClient += Number(vm.kpis[i].nbClient);
                vm.nbClientVisite += Number(vm.kpis[i].nbClientVisite);
                vm.nbClientNonVisite += Number(vm.kpis[i].nbClientNonVisite);
                vm.totalNbrClientInRoute += Number(vm.kpis[i].totalNbrClientInRoute);
                vm.sumnbclsbl += Number(vm.kpis[i].nbclsBl);
                vm.sumRecette += Number(vm.kpis[i].recette);
                vm.sumRecouvrement += Number(vm.kpis[i].recouvrement);



                // call pie
                vm.piekpis(vm.filter.year, vm.filter.month, vm.filter.day, vm.kpis[i].code)
            }


            $scope.$apply();
        }, function (error) {
            console.log(error);
        });
    };

    vm.filterByCommercialMonthGlobal = function () {
        if (vm.filter != null) {
            var url = 'rapports/filterByCommercialMonth/' + vm.filter.year + '/' + vm.filter.month;
            if (vm.filter.day) {
                url += `/${vm.filter.day}`;
            }
        } else {
            var url = 'rapports/filterByCommercialMonth';
        }

        WS.get(url).then(function (response) {
            vm.kpisglobal = response.data.table;

            $scope.$apply();
        }, function (error) {
            console.log(error);
        });
    };

    vm.piekpis = function (year, month, day, user_code) {
        //year, month, day, user_code
        if (vm.filter.day == undefined || vm.filter.day == '') {
            var day = 'null';
        } else {
            var day = vm.filter.day;

        }
        var url = 'rapports/piekpisforuser/' + year + '/' + month + '/' + day + '/' + user_code;
        WS.getScache(url)
            .then(function (response) {
                var chart;
                var legend;
                var selected;

                var types = response.data;

                function generateChartData() {
                    var chartData = [];
                    for (var i = 0; i < types.length; i++) {
                        if (i == selected) {
                            for (var x = 0; x < types[i].subs.length; x++) {
                                chartData.push({
                                    type: types[i].subs[x].type,
                                    percent: types[i].subs[x].percent.toFixed(3),
                                    color: types[i].color,
                                    pulled: true
                                });
                            }
                        } else {
                            chartData.push({
                                type: types[i].type,
                                percent: types[i].percent.toFixed(3),
                                color: types[i].color,
                                id: i
                            });
                        }
                    }
                    return chartData;
                }
                var id = "piekpi-" + month + "-" + user_code;

                function getNumberSeparators() {

                    // default
                    var res = {
                        "decimal": ".",
                        "thousand": ""
                    };

                    // convert a number formatted according to locale
                    var str = parseFloat(1234.56).toLocaleString();

                    // if the resulting number does not contain previous number
                    // (i.e. in some Arabic formats), return defaults
                    if (!str.match("1"))
                        return res;

                    // get decimal and thousand separators
                    res.decimal = str.replace(/.*4(.*)5.*/, "$1");
                    res.thousand = str.replace(/.*1(.*)2.*/, "$1");

                    // return results
                    return res;
                }

                /**
                 * Get locale settings
                 */
                var separators = getNumberSeparators();
                AmCharts.makeChart(id, {
                    "type": "pie",
                    "theme": "light",
                    "decimalSeparator": separators.decimal,
                    "thousandsSeparator": separators.thousand,
                    "dataProvider": generateChartData(),
                    //"labelText": "[[title]]: [[value]]",
                    //"balloonText": "[[title]]: [[value]]",
                    "titleField": "type",
                    "valueField": "percent",
                    "outlineColor": "#FFFFFF",
                    "outlineAlpha": 0.8,
                    "outlineThickness": 2,
                    "colorField": "color",
                    "pulledField": "pulled",
                    "listeners": [{
                        "event": "clickSlice",
                        "method": function (event) {
                            var chart = event.chart;
                            if (event.dataItem.dataContext.id != undefined) {
                                selected = event.dataItem.dataContext.id;
                            } else {
                                selected = undefined;
                            }
                            chart.dataProvider = generateChartData();
                            chart.validateData();
                        }
                    }],
                    "innerRadius": "65%",
                    "labelsEnabled": false,
                    "autoMargins": false,
                    "marginTop": 0,
                    "marginBottom": 0,
                    "marginLeft": 0,
                    "marginRight": 0,
                    "pullOutRadius": 0,
                    "labelRadius": 0,
                    "legend": {
                        "position": "bottom",
                        "marginRight": 0,
                        "align": "left",
                        "equalWidths": true,
                        "valueWidth": 70,
                        "horizontalGap": 5,
                        "marginLeft": 0,
                        "marginBottom": 0,
                        "marginTop": 0,
                        "autoMargins": false
                    },
                    "export": {
                        "enabled": true
                    }
                });
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.piekpisforAllusers = function () {
        if (vm.filter.year != null && vm.filter.month != null) {
            var url = 'rapports/piekpisforAllusers/' + vm.filter.year + '/' + vm.filter.month;
            if (vm.filter.day) {
                url += `/${vm.filter.day}`;
            }
        } else {
            var url = 'rapports/piekpisforAllusers';
        }

        WS.getScache(url)
            .then(function (response) {
                var chart;
                var legend;
                var selected;

                var types = response.data;

                function generateChartData() {
                    var chartData = [];
                    for (var i = 0; i < types.length; i++) {
                        if (i == selected) {
                            for (var x = 0; x < types[i].subs.length; x++) {
                                chartData.push({
                                    type: types[i].subs[x].type,
                                    percent: types[i].subs[x].percent.toFixed(3),
                                    color: types[i].color,
                                    pulled: true
                                });
                            }
                        } else {
                            chartData.push({
                                type: types[i].type,
                                percent: types[i].percent.toFixed(3),
                                color: types[i].color,
                                id: i
                            });
                        }
                    }
                    return chartData;
                }
                var id = "piekpisforAllusers";

                function getNumberSeparators() {

                    // default
                    var res = {
                        "decimal": ".",
                        "thousand": ""
                    };

                    // convert a number formatted according to locale
                    var str = parseFloat(1234.56).toLocaleString();

                    // if the resulting number does not contain previous number
                    // (i.e. in some Arabic formats), return defaults
                    if (!str.match("1"))
                        return res;

                    // get decimal and thousand separators
                    res.decimal = str.replace(/.*4(.*)5.*/, "$1");
                    res.thousand = str.replace(/.*1(.*)2.*/, "$1");

                    // return results
                    return res;
                }

                /**
                 * Get locale settings
                 */
                var separators = getNumberSeparators();
                AmCharts.makeChart(id, {
                    "type": "pie",
                    "theme": "light",
                    "decimalSeparator": separators.decimal,
                    "thousandsSeparator": separators.thousand,
                    "dataProvider": generateChartData(),
                    //"labelText": "[[title]]: [[value]]",
                    //"balloonText": "[[title]]: [[value]]",
                    "titleField": "type",
                    "valueField": "percent",
                    "outlineColor": "#FFFFFF",
                    "outlineAlpha": 0.8,
                    "outlineThickness": 2,
                    "colorField": "color",
                    "pulledField": "pulled",
                    "listeners": [{
                        "event": "clickSlice",
                        "method": function (event) {
                            var chart = event.chart;
                            if (event.dataItem.dataContext.id != undefined) {
                                selected = event.dataItem.dataContext.id;
                            } else {
                                selected = undefined;
                            }
                            chart.dataProvider = generateChartData();
                            chart.validateData();
                        }
                    }],
                    "innerRadius": "65%",
                    "labelsEnabled": false,
                    "autoMargins": false,
                    "marginTop": 0,
                    "marginBottom": 0,
                    "marginLeft": 0,
                    "marginRight": 0,
                    "pullOutRadius": 0,
                    "labelRadius": 0,
                    "legend": {
                        "position": "bottom",
                        "marginRight": 0,
                        "align": "left",
                        "equalWidths": true,
                        "valueWidth": 70,
                        "horizontalGap": 5,
                        "marginLeft": 0,
                        "marginBottom": 0,
                        "marginTop": 0,
                        "autoMargins": false
                    },
                    "export": {
                        "enabled": true
                    }
                });
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    /**
     * fin Rapport commercial par mois
     */
    vm.exporterByCommercialMonth = function (tableId) {
        // if (vm.filter != null) {
        //     var url = 'rapports/filterByCommercialMonth/' + vm.filter.year + '/' + vm.filter.month;
        //     if (vm.filter.day && vm.filter.day != "") {
        //         url += `/${vm.filter.day}`;
        //     } else {
        //         url += `/null`;
        //     }
        //     if (vm.filter.user_code && vm.filter.user_code != "") {
        //         url += `/${vm.filter.user_code}`;
        //     } else {
        //         url += `/null`;
        //     }
        //     /*if(filter.user_code && filter.user_code != ""){
        //       url = url + '/' + filter.user_code
        //     }*/
        // } else {
        //     var url = 'rapports/filterByCommercialMonth';
        // }

        // WS.get(url).then(function (response) {
        //     var filename = "Rapport-Commercial-" + response.data.year + '-' + response.data.month;

        //     var file = {
        //         sheetid: "Rapport-Commercial-" + response.data.year + '-' + response.data.month,
        //         headers: true,
        //         caption: {
        //             title: "Rapport-Commercial-" + response.data.year + '-' + response.data.month,
        //             style: 'font-size: 50px; color:blue;'
        //         },
        //         column: {
        //             style: 'font-size:20px;background:#d1d1d1;'
        //         },
        //         columns: [
        //             { columnid: 'user', title: 'Commercial' },
        //             { columnid: 'nbClient', title: 'Nbre Client' },
        //             { columnid: 'nbVisite', title: 'Nbre Visite' },
        //             { columnid: 'nbbl', title: 'Nbre BL ' },
        //             { columnid: 'vente', title: 'Vente' },
        //             { columnid: 'credit', title: 'Credit' },

        //         ],
        //         row: {
        //             style: function (sheet, row, rowidx) {
        //                 return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
        //             }
        //         }
        //     };
        //     alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data.table]);
        // }, function (error) {
        //     console.log(error);
        // });


        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;
        var title = "Rapport-";
        title += vm.filter.year + '-' + vm.filter.month + '-' + vm.filter.day + '_||_' + vm.filter.user_code;

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);



    };
    vm.export_rapport_13_14 = function (tableId, title='Rapport') {



        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;
        //var title = "Rapport DN par Produit-";
        // title += vm.filter.year + '-' + vm.filter.month + '-' + vm.filter.day + '_||_' + vm.filter.user_code;

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);



    };

    vm.analyseTournee = function (date = null) {
        var url = (date == null) ? 'entetecommercial/calcul/analyseTournee' : 'entetecommercial/calcul/analyseTournee/' + date;
        WS.get(url)
            .then(function (response) {
                vm.analysetournees = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.tauxTournee = function (date = null) {
        WS.get('entetecommercial/calcul/tauxTournee/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.tauxtournees = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    // vm.traitementTourne = function (user_code, tauxtournees) {
    //     if (!tauxtournees) return;
    //     for (var i = 0; i < tauxtournees.length; i++) {
    //         if (tauxtournees[i].route.user_code == user_code) {
    //             if (tauxtournees[i].tournees.length > 0) {
    //                 return tauxtournees[i].tournees[0].visite * 100 / tauxtournees[i].tournees[0].clients;
    //             }
    //         }
    //     }
    //     return 0;
    // };

    vm.traitementTourne = function (user_code, tauxtournees) {
        if (!tauxtournees) return 0;
        for (var i = 0; i < tauxtournees.length; i++) {
            if (tauxtournees[i].route.user_code == user_code) {
                if (tauxtournees[i].tournees.length > 0) {
                    return tauxtournees[i].tournees[0].visite * 100 / tauxtournees[i].tournees[0].clients;
                } else {
                    return 0;
                }
            }
        }
    };


    vm.getAnalyseFamilleParGamme = function (date_debut, date_fin, code) {
        WS.get('rapports/analyseFamilleParGamme/' + date_debut + '/' + date_fin + '/' + code)
            .then(function (response) {
                if (response.status == 200) {
                    vm.analyseFamilleParGamme = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.exportrapportparperiode = function (date_debut, date_fin, type, code) {
        WS.get('rapports/exportRapportPeriodique' + '/' + date_debut + '/' + date_fin + '/' + type + '/' + code).then(
            function (response) {
                var count = response.data.length;

                //var filename = 'Rapport-Tournee-'+moment().format("DD-MM-YYYY_HH:mm:ss");
                var filename = 'Rapport-Periodique-' + moment().format("DD-MM-YYYY HH:mm:ss");

                var file = {
                    sheetid: 'Rapport-Periodique-' + moment().format("DD-MM-YYYY HH:mm:ss"),
                    headers: true,
                    caption: {
                        title: 'Rapport-Periodique-' + moment().format("DD-MM-YYYY HH:mm:ss"),
                        style: 'font-size: 50px; color:blue;'
                    },
                    column: {
                        style: 'font-size:20px;background:#d1d1d1;'
                    },
                    columns: [

                        { columnid: 'cashvan', title: 'Commercial' },
                        { columnid: 'client', title: 'Client' },
                        { columnid: 'adresse_facturation', title: 'Adresse' },
                        { columnid: 'code', title: 'code ' },
                        { columnid: 'code_a_barre', title: 'code_a_barre' },
                        { columnid: 'date_visite', title: 'Date' },
                        { columnid: 'type', title: 'type' },
                        { columnid: 'visite_code', title: 'Code Visite' },
                        { columnid: 'vente', title: type }

                    ],
                    row: {
                        style: function (sheet, row, rowidx) {
                            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                        }
                    }
                };

                alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
            },
            function (error) {
                console.log(error);
            }
        );
    };

    vm.filterfieldnames = function (choix, fieldvalue) {
        //if(fieldvalue == undefined) return;
        if (fieldvalue == undefined) {
            switch (choix) {
                case 'marque':
                    vm.getMarque();
                    break;
                case 'fournisseur':
                    vm.getFournisseurs();
                    break;
                case 'gamme':
                    vm.getGammes();
                    break;
                case 'famille':
                    vm.getFamilles();
                    break;
                case 'sousfamille':
                    vm.getSousfamilles();
                    break;
                case 'produit':
                    vm.getAllproduits();
                    break;
            }

            return;
        }
        WS.get('produits/selectProdByChoix/' + choix + '_code/' + fieldvalue).then(
            (response) => {
                vm[choix + 's'] = response.data;
                $scope.$apply();
            },
            (error) => {
                console.log(error);
            })
    }


    vm.filterVenteCommercial = function (fc_date_debut, fc_date_fin, type) {

        WS.get('rapports/filterVenteCommercial/' + fc_date_debut + "/" + fc_date_fin + "/" + type)
            .then(function (response) {
                if (response.status == 200) {
                    vm.fc_vente_commercial = response.data;
                    vm.totalvente = response.data.totalvente;
                    vm.totalventeCommercial = response.data.totalventeParComm;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.filterSuiviCommercial = function (fc_user_code, fc_date_debut, fc_date_fin, type) {
        WS.get('rapports/filterSuiviCommercial/' + fc_user_code + "/" + fc_date_debut + "/" + fc_date_fin + "/" + type)
            .then(function (response) {
                if (response.status == 200) {
                    vm.fc_suivi_commercial = response.data.entete;
                    vm.totvente = response.data.totvente;
                    vm.totvisite = response.data.totvisite;
                    vm.totnbr_bl = response.data.totnbr_bl;
                    vm.totnbr_bl_paye = response.data.totnbr_bl_paye;
                    vm.totnbr_bl_impaye = response.data.totnbr_bl_impaye;
                    vm.totnbr_avoir = response.data.totnbr_avoir;
                    vm.totespece_cheque = response.data.totespece_cheque;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    /**
     * export to csv
     */


    vm.table2CSV = function (tableId) {
        $(tableId).tableToCSV();
    };

    vm.stat_chargements = (date_debut = null, date_fin = null, commercial = null, colisage = 'colis_non') => {
        WS.get('rapports/stat_chargements/' + date_debut + "/" + date_fin + "/" + commercial + "/" + colisage)
            .then(function (response) {
                if (response.status == 200) {
                    vm.chargements_stats = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.totalventeBlVisite = (date_debut = null, date_fin = null, commercial = null, type_entete = 'bl') => {
        WS.get('rapports/totalventeBlVisite/' + date_debut + "/" + date_fin + "/" + commercial + "/" + type_entete)
            .then(function (response) {
                if (response.status == 200) {
                    vm.totalventeblvisite = response.data;
                    //console.log('totalvente', vm.totalventeblvisite);
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.top10Vente = (date_debut = null, date_fin = null, commercial = null, type = null, type_entete = 'bl') => {
        vm.typeoftop = (type) ? type : 'g';
        WS.get('rapports/top10Vente/' + date_debut + "/" + date_fin + "/" + commercial + "/" + type + "/" + type_entete)
            .then(function (response) {
                if (response.status == 200) {
                    vm.top10vente = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.listClientByType = (date_debut = null, date_fin = null, commercial = null, type = null) => {
        var type = (type) ? type : 'g';
        var data = {
            date_debut, 
            date_fin,
            commercial,
            type,
            activite_codes : vm.clsblbytype_activite_codes,
            categorie_codes : vm.clsblbytype_categorie_codes
        };
        //console.log(data);
        WS.post('rapports/listClientByType', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.listClientsByType = response.data.cls;
                    vm.th_types = response.data.th_types;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getetatcreditByCommercial = function (date_debut = null, date_fin = null, commercial = null, route = null, gouvernorat = null) {
        var url = 'rapports/getCreditByCommercial/' + date_debut + "/" + date_fin + "/" + commercial + "/" + route + "/" + gouvernorat;

        WS.get(url)
            .then(function (response) {
                if (response.status == 200) {
                    vm.etatcreditByCommercial = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.exportetatcreditByCommercial = function (date_debut = null, date_fin = null, commercial = null, route = null, gouvernorat = null) {
        var url = 'rapports/getCreditByCommercial/' + date_debut + "/" + date_fin + "/" + commercial + "/" + route + "/" + gouvernorat;

        WS.get(url).then(function (response) {
            var filename = "Credit By commercial-" + date_debut + '-' + date_fin;

            var file = {
                sheetid: "Credit By commercial-" + date_debut + '-' + date_fin,
                headers: true,
                caption: {
                    title: "Credit By commercial-" + date_debut + '-' + date_fin,
                    style: 'font-size: 50px; color:blue;'
                },
                column: {
                    style: 'font-size:20px;background:#d1d1d1;'
                },
                columns: [
                    { columnid: 'code', title: 'Code' },
                    { columnid: 'code_a_barre', title: 'Code Barre' },
                    { columnid: 'nom', title: 'Nom' },
                    { columnid: 'prenom', title: 'Prenom' },
                    { columnid: 'routing_code', title: 'Routing' },
                    { columnid: 'adresse_facturation', title: 'Adresse' },
                    { columnid: 'zone', title: 'Zone' },
                    { columnid: 'mobile', title: 'Mobile' },
                    { columnid: 'date_visite', title: 'Date Visite' },
                    { columnid: 'commentaire', title: 'Commentaire' },
                    { columnid: 'date_credit', title: 'Date Credit' },
                    { columnid: 'encours', title: 'Credit' },
                ],
                row: {
                    style: function (sheet, row, rowidx) {
                        return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                    }
                }
            };
            alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
        }, function (error) {
            console.log(error);
        });
    };


    vm.getlistcreditByCommercial = function (date_debut = null, date_fin = null, commercial = null, gouvernorat = null) {
        var url = 'rapports/getListCreditByCommercial/' + date_debut + "/" + date_fin + "/" + commercial + "/" + gouvernorat;

        WS.get(url)
            .then(function (response) {
                if (response.status == 200) {
                    vm.listcreditByCommercial = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.exportlistcreditByCommercial = function (date_debut = null, date_fin = null, commercial = null, gouvernorat = null) {
        var url = 'rapports/getListCreditByCommercial/' + date_debut + "/" + date_fin + "/" + commercial + "/" + gouvernorat;
        WS.get(url).then(function (response) {
            var filename = "List Credit By commercial-" + date_debut + '-' + date_fin;

            var file = {
                sheetid: " List Credit By commercial-" + date_debut + '-' + date_fin,
                headers: true,
                caption: {
                    title: "Credit By commercial-" + date_debut + '-' + date_fin,
                    style: 'font-size: 50px; color:blue;'
                },
                column: {
                    style: 'font-size:20px;background:#d1d1d1;'
                },
                columns: [
                    { columnid: 'code', title: 'Code' },
                    { columnid: 'code_a_barre', title: 'Code Barre' },
                    { columnid: 'nom', title: 'Nom' },
                    { columnid: 'prenom', title: 'Prenom' },
                    { columnid: 'routing_code', title: 'Routing' },
                    { columnid: 'adresse_facturation', title: 'Adresse' },
                    { columnid: 'mobile', title: 'Mobile' },
                    { columnid: 'user', title: 'Commercial' },
                    { columnid: 'code_bl', title: 'BL ' },
                    { columnid: 'montant', title: 'Montant' },
                    { columnid: 'credit', title: 'Credit' },
                    { columnid: 'date', title: 'Date ' },
                    { columnid: 'date_echeance_credit', title: 'Date Credit' },
                ],
                row: {
                    style: function (sheet, row, rowidx) {
                        return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                    }
                }
            };
            alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
        }, function (error) {
            console.log(error);
        });
    };



    vm.getreglementByCommercial = function (date_debut, date_fin, commercial = null) {
        var url = 'rapports/getAllRegelementByCommercial/' + date_debut + "/" + date_fin + "/" + commercial;
        WS.get(url)
            .then(function (response) {
                if (response.status == 200) {
                    vm.reglementByCommercial = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };




    vm.exportreglementByCommercial = function (date_debut, date_fin, commercial = null) {
        var url = 'rapports/getAllRegelementByCommercial/' + date_debut + "/" + date_fin + "/" + commercial;
        WS.get(url).then(function (response) {
            var filename = "Reglement By commercial-" + date_debut + '-' + date_fin;

            var file = {
                sheetid: "Reglement By commercial-" + date_debut + '-' + date_fin,
                headers: true,
                caption: {
                    title: "Reglement By commercial-" + date_debut + '-' + date_fin,
                    style: 'font-size: 50px; color:blue;'
                },
                column: {
                    style: 'font-size:20px;background:#d1d1d1;'
                },
                columns: [
                    { columnid: 'code', title: 'Code' },
                    { columnid: 'code_a_barre', title: 'Code Barre' },
                    { columnid: 'client', title: 'Client' },
                    { columnid: 'adresse_facturation', title: 'Adresse' },
                    { columnid: 'commercial', title: 'Commercial' },
                    { columnid: 'montant', title: 'Montant' },
                    { columnid: 'espece', title: 'Espece' },
                    { columnid: 'cheque', title: 'Cheque' },
                    { columnid: 'traite', title: 'Traite' },
                    { columnid: 'credit', title: 'Credit' },
                    { columnid: 'rs', title: 'Retenue' },
                    { columnid: 'montant_avoir', title: 'Avoir' },
                    { columnid: 'recouvrement', title: 'Recouvrement' }
                ],
                row: {
                    style: function (sheet, row, rowidx) {
                        return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                    }
                }
            };
            alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
        }, function (error) {
            console.log(error);
        });
    };


    vm.getgratuiteByCommercial = function (date_debut, date_fin, commercial = null) {
        var url = 'rapports/getgratuiteByCommercial/' + date_debut + "/" + date_fin + "/" + commercial;
        WS.get(url)
            .then(function (response) {
                if (response.status == 200) {
                    vm.gratuiteByCommercial = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getMargeparVendeur = function (date_debut, date_fin, commercial) {
        var url = 'rapports/getMargeParVendeur/' + date_debut + "/" + date_fin + "/" + commercial;
        WS.get(url)
            .then(function (response) {
                if (response.status == 200) {
                    vm.margeparvendeur = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.exportMargeparVendeur = function (date_debut, date_fin, commercial) {
        var url = 'rapports/getMargeParVendeur/' + date_debut + "/" + date_fin + "/" + commercial;
        WS.get(url).then(function (response) {
            var filename = "Marge par commercial-" + commercial + '-' + date_debut + '-' + date_fin;

            var file = {
                sheetid: "Marge par commercial-" + commercial + '-' + date_debut + '-' + date_fin,
                headers: true,
                caption: {
                    title: "Marge par commercial-" + commercial + '-' + date_debut + '-' + date_fin,
                    style: 'font-size: 50px; color:blue;'
                },
                column: {
                    style: 'font-size:20px;background:#d1d1d1;'
                },
                columns: [
                    { columnid: 'code', title: 'Code' },
                    { columnid: 'libelle', title: 'Produit' },
                    { columnid: 'user_code', title: 'Commercial code' },
                    { columnid: 'vente_net', title: 'Vente net' },
                    { columnid: 'vente_net_caisse', title: 'Vnete net / caisse' },
                    { columnid: 'commission', title: 'Commission' },
                    { columnid: 'marge', title: 'Marge' },
                ],
                row: {
                    style: function (sheet, row, rowidx) {
                        return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                    }
                }
            };
            alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
        }, function (error) {
            console.log(error);
        });
    };

    vm.getvisiteByCommercial = function (date_debut, date_fin, commercial = null) {
        var url = 'rapports/getAllVisiteByCommercial/' + date_debut + "/" + date_fin + "/" + commercial;
        WS.get(url)
            .then(function (response) {
                if (response.status == 200) {
                    vm.visiteByCommercial = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.exportvisiteByCommercial = function (date_debut, date_fin, commercial = null) {
        var url = 'rapports/getAllVisiteByCommercial/' + date_debut + "/" + date_fin + "/" + commercial;
        WS.get(url).then(function (response) {
            var filename = "Visite By commercial-" + date_debut + '-' + date_fin;

            var file = {
                sheetid: "Visite By commercial-" + date_debut + '-' + date_fin,
                headers: true,
                caption: {
                    title: "Visite By commercial-" + date_debut + '-' + date_fin,
                    style: 'font-size: 50px; color:blue;'
                },
                column: {
                    style: 'font-size:20px;background:#d1d1d1;'
                },
                columns: [
                    { columnid: 'code', title: 'Code' },
                    { columnid: 'code_a_barre', title: 'Code Barre' },
                    { columnid: 'client', title: 'Client' },
                    { columnid: 'adresse_facturation', title: 'Adresse' },
                    { columnid: 'commercial', title: 'Commercial' },
                    { columnid: 'cause', title: 'Cause' },
                    { columnid: 'commentaire', title: 'Commentaire' },
                    { columnid: 'date_visite', title: 'Dernier visite' },
                    { columnid: 'date_prochaineVisite', title: 'Prochaine visite' }
                ],
                row: {
                    style: function (sheet, row, rowidx) {
                        return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                    }
                }
            };
            alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
        }, function (error) {
            console.log(error);
        });
    };

    vm.exportsuiviCommercialClient = function (detail) {
        var url = 'rapports/suiviCommercialClient',
            suivi_cls;
        WS.get(url).then(function (response) {
            var filename = "Suivi Commercial Client-";

            var file = {
                sheetid: "Suivi Commercial Client-",
                headers: true,
                caption: {
                    title: "Suivi Commercial Client-",
                    style: 'font-size: 50px; color:blue;'
                },
                column: {
                    style: 'font-size:20px;background:#d1d1d1;'
                },
                columns: [
                    { columnid: 'code', title: 'Code' },
                    { columnid: 'client', title: 'Client' },
                    { columnid: 'adresse_facturation', title: 'Adresse' },
                    { columnid: 'mobile', title: 'Mobile' },
                    { columnid: 'categorie', title: 'Categorie' },
                    { columnid: 'nbr_bl', title: 'NB/BL' },
                    { columnid: 'chiffre_net_bl', title: ' CA  BL' },
                    { columnid: 'nbr_commande', title: 'NB/CMD ' },
                    { columnid: 'chiffre_net_cmd', title: 'CA Commande' },
                    { columnid: 'nbr_visite', title: 'NB/Visite' },
                    { columnid: 'nbr_avoir', title: 'NB/Avoir' },
                    { columnid: 'nbr_recouvrement', title: 'NB/Recouvrement' },
                    { columnid: 'total_visite', title: 'Total Visite' },
                    { columnid: 'last_visite', title: 'Date Dernier Visite' },
                    { columnid: 'commentaire', title: 'Commentaire' }
                ],
                row: {
                    style: function (sheet, row, rowidx) {
                        return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                    }
                }
            };
            alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
        }, function (error) {
            console.log(error);
        });
    };

    vm.pieGammeFamilleByZone = (date_debut, date_fin, zone_code) => {
        var user_code = null;
        var url = `rapports/pieGammeFamilleByZone/${date_debut}/${date_fin}/${zone_code}`;
        WS.getScache(url)
            .then(function (response) {
                var chart;
                var legend;
                var selected;

                var types = response.data;

                function generateChartData() {
                    var chartData = [];
                    for (var i = 0; i < types.length; i++) {
                        if (i == selected) {
                            for (var x = 0; x < types[i].subs.length; x++) {
                                chartData.push({
                                    type: types[i].subs[x].type,
                                    percent: types[i].subs[x].percent.toFixed(3),
                                    color: types[i].color,
                                    pulled: true
                                });
                            }
                        } else {
                            chartData.push({
                                type: types[i].type,
                                percent: types[i].percent.toFixed(3),
                                color: types[i].color,
                                id: i
                            });
                        }
                    }
                    vm.chartdatalengthHeight = chartData.length;
                    $scope.$apply();
                    return chartData;
                }

                function getNumberSeparators() {

                    // default
                    var res = {
                        "decimal": ".",
                        "thousand": ""
                    };

                    // convert a number formatted according to locale
                    var str = parseFloat(1234.56).toLocaleString();

                    // if the resulting number does not contain previous number
                    // (i.e. in some Arabic formats), return defaults
                    if (!str.match("1"))
                        return res;

                    // get decimal and thousand separators
                    res.decimal = str.replace(/.*4(.*)5.*/, "$1");
                    res.thousand = str.replace(/.*1(.*)2.*/, "$1");

                    // return results
                    return res;
                }

                /**
                 * Get locale settings
                 */
                var separators = getNumberSeparators();
                AmCharts.makeChart('pieGammeFamilleByZone', {
                    "type": "pie",
                    "theme": "light",
                    "decimalSeparator": separators.decimal,
                    "thousandsSeparator": separators.thousand,
                    "dataProvider": generateChartData(),
                    //"labelText": "[[title]]: [[value]]",
                    //"balloonText": "[[title]]: [[value]]",
                    "titleField": "type",
                    "valueField": "percent",
                    "outlineColor": "#FFFFFF",
                    "outlineAlpha": 0.8,
                    "outlineThickness": 2,
                    "colorField": "color",
                    "pulledField": "pulled",
                    "listeners": [{
                        "event": "clickSlice",
                        "method": function (event) {
                            var chart = event.chart;
                            if (event.dataItem.dataContext.id != undefined) {
                                selected = event.dataItem.dataContext.id;
                            } else {
                                selected = undefined;
                            }
                            chart.dataProvider = generateChartData();
                            chart.validateData();
                        }
                    }],
                    "innerRadius": "65%",
                    "labelsEnabled": false,
                    "autoMargins": false,
                    "marginTop": 0,
                    "marginBottom": 0,
                    "marginLeft": 0,
                    "marginRight": 0,
                    "pullOutRadius": 0,
                    "labelRadius": 0,
                    "legend": {
                        "position": "bottom",
                        "marginRight": 0,
                        "align": "left",
                        "equalWidths": true,
                        "valueWidth": 70,
                        "horizontalGap": 5,
                        "marginLeft": 0,
                        "marginBottom": 0,
                        "marginTop": 0,
                        "autoMargins": false
                    },
                    "export": {
                        "enabled": true
                    }
                });
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.month = moment().format('YYYY-MM');

    vm.kpi_dd = function () {
        _.mixin({
            capitalize: function (string) {
                return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
            }
        });
        var monthname = moment(vm.month).format('MMMM');
        vm.monthname = _(monthname).capitalize();
        vm.yearname = moment(vm.month).format('YYYY');
        vm.startOfMonth = moment(vm.month).startOf('month').format('YYYY-MM-DD');
        vm.endOfMonth = moment(vm.month).endOf('month').format('DD');
        vm.alldays = [];
        var countOutDim = 0;
        for (var i = 1; i <= Number(vm.endOfMonth); i++) {
            i = (i <= 9) ? '0' + i : i;
            vm.alldays.push({
                day: i,
                name: moment(vm.month + '-' + i).format('ddd')
            });
            if (moment(vm.month + '-' + i).format('dddd') != 'dimanche') {
                countOutDim++;
            }

        }
        vm.countOutDim = countOutDim;

        $(".datepicker-dashb").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.month = date;
                $scope.$apply();
                //console.log(vm.month);
            }
        });

        //console.log(vm.month, vm.kpi_commercial);
    };

    vm.kpi_objectifs = function () {
        WS.getScache('rapports/ObejectifsByCommercialsMonth' + `?commercial=${vm.kpi_commercial}&type_depot=${vm.kpi_type_depot}&month=${vm.month}&produit_code=${vm.kpi_produit}&gamme_code=${vm.kpi_gamme}`).then(function (response) {
            vm.tableobjectifs = [];
            response.data.forEach(function (element, index) {
                var date_debut = element.date_debut;
                var date_fin = element.date_fin;
                var days_objs = [];
                var nbr_jour_travaille = 0;
                for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                    i = (i <= 9) ? '0' + i : i;
                    var compareDate = moment(vm.month + '-' + i, "YYYY-MM-DD");
                    var day_obj = moment(vm.month + '-' + i).format('dddd');
                    if (
                        (compareDate.isBetween(element.date_debut, element.date_fin) ||
                            compareDate.isSame(element.date_debut) ||
                            compareDate.isSame(element.date_fin)) && day_obj != 'dimanche') {


                        days_objs.push({
                            chiffre: element.chiffre,
                            value: element.obj_visite,
                            day: day_obj,
                            date_visite: moment(vm.month + '-' + i).format('YYYY-MM-DD')
                        });
                        nbr_jour_travaille++;

                    } else {
                        days_objs.push({
                            chiffre: 0,
                            value: 0,
                            day: day_obj,
                            date_visite: moment(vm.month + '-' + i).format('YYYY-MM-DD')
                        });
                    }
                }
                vm.tableobjectifs.push({
                    code: element.code,
                    nom: element.nom,
                    prenom: element.prenom,
                    days_objs: days_objs,
                    total: days_objs.reduce((s, f) => s + f.value, 0),
                    totalchiffre: days_objs.reduce((s, f) => s + f.chiffre, 0),
                    nbr_jour_travaille: nbr_jour_travaille
                });
            });

            var tabtotal = [];
            var tabtotalchiffre = [];
            vm.tableobjectifs.forEach(function (element, index) {
                element.days_objs.forEach(function (obj, ind) {
                    tabtotal[ind] = (tabtotal[ind]) ? tabtotal[ind] + obj.value : obj.value;
                    tabtotalchiffre[ind] = (tabtotalchiffre[ind]) ? tabtotalchiffre[ind] + obj.chiffre : obj.chiffre;
                });
            });

            vm.tabtotal = tabtotal;


            vm.tabtotalchiffre = tabtotalchiffre;

            vm.totalend = tabtotal.reduce((s, f) => s + f, 0);
            vm.totalendchiffre = tabtotalchiffre.reduce((s, f) => s + f, 0);

            vm.total_nbr_jour_trav = vm.tableobjectifs.reduce((s, f) => s + f.nbr_jour_travaille, 0);
            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.kpi_visites = function () {

        WS.getScache('rapports/kpi_visites' + `?commercial=${vm.kpi_commercial}&type_depot=${vm.kpi_type_depot}&month=${vm.month}&produit_code=${vm.kpi_produit}&gamme_code=${vm.kpi_gamme}`).then(function (response) {
            var tabvisites = [];
            response.data.users.forEach(function (user, indUser) {
                var tabusers = [];
                var nbr_jour_travaille = 0;
                response.data.visites.forEach(function (visite, indVisite) {
                    if (user.code == visite.user_code) {
                        tabusers.push({
                            date_visite: visite.date_visite,
                            nbr_visite: visite.nbr_visite,
                            day: moment(visite.date_visite).format('dddd')
                        });
                        nbr_jour_travaille++;
                    }
                });

                //tabusers
                //traitement with kpi_dd global
                for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                    i = (i <= 9) ? '0' + i : i;
                    var date_trait = vm.month + '-' + i;
                    var j = 0;
                    tabusers.forEach(function (element, index) {
                        if (element.date_visite == date_trait) {
                            j++;
                        }
                    });
                    if (j == 0) {
                        tabusers.push({
                            date_visite: date_trait,
                            nbr_visite: 0,
                            day: moment(date_trait).format('dddd')
                        });
                    }
                }

                tabusers = _.sortBy(tabusers, function (node) {
                    return (new Date(node.date_visite).getTime());
                });
                tabvisites.push({
                    code: user.code,
                    nom: user.nom,
                    prenom: user.prenom,
                    visites: tabusers,
                    total: tabusers.reduce((s, f) => s + f.nbr_visite, 0),
                    nbr_jour_travaille: nbr_jour_travaille
                });
            });
            vm.tabvisites = tabvisites;
            var totalendObjs = 0;
            vm.tabvisites.forEach(function (visite, indexVis) {
                vm.tableobjectifs.forEach(function (objectif, indexObj) {
                    if (visite.code == objectif.code) {
                        var filteredItems = _.filter(visite.visites, function (item, i) {
                            return item.nbr_visite != 0;
                        });

                        var dates = _.pluck(filteredItems, 'date_visite');

                        var totobjres = 0;

                        _.each(objectif.days_objs, function (el, index) {
                            //console.log(_.contains(dates, el.date_visite), dates, el.date_visite);
                            if (_.contains(dates, el.date_visite) == true) {
                                totobjres += Number(el.value);
                            }
                        });

                        vm.tabvisites[indexVis].totalObjRealise = totobjres;
                        totalendObjs += totobjres;
                    }
                });
            });

            console.log('-_-_-_-_-_-_-__-_-__-_-_-_-_-_-_-_-_-_--__-');
            console.log('tabvisites', vm.tabvisites);
            console.log('-_-_-_-_-_-_-__-_-__-_-_-_-_-_-_-_-_-_--__-');

            var tabtotalVisite = [];
            vm.tabvisites.forEach(function (element, index) {
                element.visites.forEach(function (obj, ind) {
                    tabtotalVisite[ind] = (tabtotalVisite[ind]) ? tabtotalVisite[ind] + obj.nbr_visite : obj.nbr_visite;
                });
            });
            vm.tabtotalVisite = tabtotalVisite;
            vm.totalendVisite = tabtotalVisite.reduce((s, f) => s + f, 0);
            vm.totalendVisite = tabtotalVisite.reduce((s, f) => s + f, 0);

            vm.total_nbr_jour_visite = vm.tabvisites.reduce((s, f) => s + f.nbr_jour_travaille, 0);
            vm.totalendObjs = totalendObjs;

            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.kpi_ventes = function (type = '') {
        WS.getScache(
            'rapports/kpi_ventes' + `?commercial=${vm.kpi_commercial}&type=${type}&type_depot=${vm.kpi_type_depot}&month=${vm.month}&gamme_code=${vm.kpi_gamme}&produit_code=${vm.kpi_produit}`
        ).then(function (response) {
            var tabventes = [];
            response.data.users.forEach(function (user, indUser) {
                var tabusers = [];
                var nbr_jour_travaille = 0;
                response.data.ventes.forEach(function (vente, indvente) {
                    if (user.code == vente.user_code) {
                        tabusers.push({
                            date_vente: vente.date_vente,
                            nbr_vente: vente.nbr_vente,
                            day: moment(vente.date_vente).format('dddd')
                        });
                        nbr_jour_travaille++;
                    }
                });

                //tabusers
                //traitement with kpi_dd global
                for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                    i = (i <= 9) ? '0' + i : i;
                    var date_trait = vm.month + '-' + i;
                    var j = 0;
                    tabusers.forEach(function (element, index) {
                        if (element.date_vente == date_trait) {
                            j++;
                        }
                    });
                    if (j == 0) {
                        tabusers.push({
                            date_vente: date_trait,
                            nbr_vente: 0,
                            day: moment(date_trait).format('dddd')
                        });
                    }
                }

                tabusers = _.sortBy(tabusers, function (node) {
                    return (new Date(node.date_vente).getTime());
                });
                tabventes.push({
                    code: user.code,
                    nom: user.nom,
                    prenom: user.prenom,
                    ventes: tabusers,
                    total: tabusers.reduce((s, f) => s + f.nbr_vente, 0),
                    nbr_jour_travaille: nbr_jour_travaille
                });
            });
            vm.tabventes = tabventes;

            var tabtotalvente = [];
            vm.tabventes.forEach(function (element, index) {
                element.ventes.forEach(function (obj, ind) {
                    if (tabtotalvente[ind]) {
                        tabtotalvente[ind].nbr_vente = (tabtotalvente[ind].nbr_vente) ? tabtotalvente[ind].nbr_vente + obj.nbr_vente : obj.nbr_vente;
                    } else {
                        tabtotalvente.push({ nbr_vente: obj.nbr_vente, date_vente: obj.date_vente });

                    }
                });
            });
            vm.tabtotalvente = tabtotalvente;
            vm.totalendvente = tabtotalvente.reduce((s, f) => s + f.nbr_vente, 0);

            vm.total_nbr_jour_vente = vm.tabventes.reduce((s, f) => s + f.nbr_jour_travaille, 0);
            // console.log('tabtotalvente', tabtotalvente);
            // console.log('totalendvente', vm.totalendvente);

            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.kpi_total_ttc = function (type = '') {
        WS.getScache('rapports/kpi_total_ttc' + `?commercial=${vm.kpi_commercial}&type=${type}&type_depot=${vm.kpi_type_depot}&month=${vm.month}&produit_code=${vm.kpi_produit}&gamme_code=${vm.kpi_gamme}`).then(function (response) {
            var tabttc = [];
            response.data.users.forEach(function (user, indUser) {
                var tabusers = [];
                var nbr_jour_travaille = 0;
                response.data.ventes.forEach(function (vente, indvente) {
                    if (user.code == vente.user_code) {
                        tabusers.push({
                            date_vente: vente.date_vente,
                            total_ttc: parseFloat(vente.total_ttc),
                            day: moment(vente.date_vente).format('dddd')
                        });
                        nbr_jour_travaille++;
                    }
                });
                //tabusers
                //traitement with kpi_dd global
                for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                    i = (i <= 9) ? '0' + i : i;
                    var date_trait = vm.month + '-' + i;
                    var j = 0;
                    tabusers.forEach(function (element, index) {
                        if (element.date_vente == date_trait) {
                            j++;
                        }
                    });
                    if (j == 0) {
                        tabusers.push({
                            date_vente: date_trait,
                            total_ttc: 0,
                            day: moment(date_trait).format('dddd')
                        });
                    }
                }

                tabusers = _.sortBy(tabusers, function (node) {
                    return (new Date(node.date_vente).getTime());
                });
                var total = tabusers.reduce((s, f) => s + f.total_ttc, 0);
                tabttc.push({
                    code: user.code,
                    nom: user.nom,
                    prenom: user.prenom,
                    ventes: tabusers,
                    total: parseFloat(total).toFixed(0),
                    nbr_jour_travaille: nbr_jour_travaille
                });
            });
            vm.tabttc = tabttc;

            var tabtotalttc = [];
            vm.tabttc.forEach(function (element, index) {
                element.ventes.forEach(function (obj, ind) {
                    tabtotalttc[ind] = (tabtotalttc[ind]) ? parseFloat(tabtotalttc[ind]) + obj.total_ttc : obj.total_ttc;
                    tabtotalttc[ind] = parseFloat(tabtotalttc[ind]).toFixed(0);
                });
            });
            vm.tabtotalttc = tabtotalttc;

            var totalendttc = tabtotalttc.reduce((s, f) => parseFloat(s) + parseFloat(f), 0);
            vm.totalendttc = parseFloat(totalendttc).toFixed(0);

            vm.total_nbr_jour_ttc = vm.tabttc.reduce((s, f) => s + f.nbr_jour_travaille, 0);

            //vm.tabtotalttcPercent //ttc percent
            /**
             * debut percent vente ttc
             */
            var tabtotalttcPercent = [];
            vm.tabttc.forEach(function (element, index) {
                element.ventes.forEach(function (obj, ind) {

                    vm.tabtotalttc.forEach(function (e_total, i_total) {
                        if (i_total == ind) {
                            if (e_total > 0) {
                                tabtotalttcPercent[ind] = (tabtotalttcPercent[ind]) ? parseFloat(tabtotalttcPercent[ind]) + (obj.total_ttc * 100 / e_total) : (obj.total_ttc * 100 / e_total);
                                tabtotalttcPercent[ind] = parseFloat(tabtotalttcPercent[ind]).toFixed(0);
                            } else {
                                tabtotalttcPercent[ind] = (tabtotalttcPercent[ind]) ? parseFloat(tabtotalttcPercent[ind]) + 0 : 0;
                                tabtotalttcPercent[ind] = parseFloat(tabtotalttcPercent[ind]).toFixed(0);
                            }
                        }
                    });


                });
            });
            vm.tabtotalttcPercent = tabtotalttcPercent;
            var totalendttcPercent = tabtotalttcPercent.reduce((s, f) => parseFloat(s) + parseFloat(f), 0) / tabtotalttcPercent.length;
            vm.totalendttcPercent = parseFloat(totalendttcPercent).toFixed(0);

            /**
             * fin percent vente ttc
             */


            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.kpiChartLinettc = function (type = '') {
        WS.getScache('rapports/kpiChartLinettc' + `?commercial=${vm.kpi_commercial}&month=${type}&month=${vm.month}&produit_code=${vm.kpi_produit}&gamme_code=${vm.kpi_gamme}`).then(function (response) {
            var tabusers = [];
            response.data.ventes.forEach(function (vente, indvente) {
                tabusers.push({
                    date_vente: vente.date_vente,
                    total_ttc: vente.total_ttc,
                    day: moment(vente.date_vente).format('DD ddd')
                });

            });

            //tabusers
            //traitement with kpi_dd global
            for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                i = (i <= 9) ? '0' + i : i;
                var date_trait = vm.month + '-' + i;
                var j = 0;
                tabusers.forEach(function (element, index) {
                    if (element.date_vente == date_trait) {
                        j++;
                    }
                });
                if (j == 0) {
                    tabusers.push({
                        date_vente: date_trait,
                        total_ttc: 0,
                        day: moment(date_trait).format('DD ddd')
                    });
                }
            }

            tabusers = _.sortBy(tabusers, function (node) {
                return (new Date(node.date_vente).getTime());
            });


            /**
             * traitement d'objectif 
             */
            vm.traitementobjectif = [];
            response.data.objectifs.forEach(function (element, index) {
                var date_debut = element.date_debut;
                var date_fin = element.date_fin;
                var days_objs = [];
                for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                    i = (i <= 9) ? '0' + i : i;
                    var compareDate = moment(vm.month + '-' + i, "YYYY-MM-DD");
                    var day_obj = moment(vm.month + '-' + i).format('dddd');
                    if (
                        (compareDate.isBetween(element.date_debut, element.date_fin) ||
                            compareDate.isSame(element.date_debut) ||
                            compareDate.isSame(element.date_fin)) && day_obj != 'dimanche') {

                        days_objs.push({
                            chiffre: element.chiffre,
                            date_visite: moment(vm.month + '-' + i).format('YYYY-MM-DD')
                        });
                    } else {
                        days_objs.push({
                            chiffre: 0,
                            date_visite: moment(vm.month + '-' + i).format('YYYY-MM-DD')
                        });
                    }
                }
                vm.traitementobjectif.push({
                    days_objs: days_objs
                });
            });

            var tabtotalchiffre = [];
            vm.traitementobjectif.forEach(function (element, index) {
                element.days_objs.forEach(function (obj, ind) {
                    tabtotalchiffre[ind] = (tabtotalchiffre[ind]) ? tabtotalchiffre[ind] + obj.chiffre : obj.chiffre;
                });
            });
            vm.totalendchiffre = tabtotalchiffre.reduce((s, f) => s + f, 0);

            vm.moyennetotalchiffre = vm.totalendchiffre / vm.tabtotalchiffre.length;
            /** 
             * fin du traitement 
             */

            tabusers.forEach(function (element, index) {
                tabtotalchiffre.forEach(function (totalchiffre, indexchiffre) {
                    if (index == indexchiffre) {
                        tabusers[index].objectif = totalchiffre;
                    }
                });
            });
            vm.chartlinettc = tabusers;
            /*console.log('vm.chartlinettc', vm.chartlinettc);
            console.log('vm.tabtotalchiffre', tabtotalchiffre);*/

            var chart = AmCharts.makeChart("chartlinettc", {
                "type": "serial",
                "addClassNames": true,
                "theme": "light",
                "autoMargins": true,
                "balloon": {
                    "adjustBorderColor": false,
                    "horizontalPadding": 10,
                    "verticalPadding": 8,
                    "color": "#ffffff"
                },

                "dataProvider": vm.chartlinettc,
                "valueAxes": [{
                    "axisAlpha": 0,
                    "position": "left",
                    "guides": [{
                        "fillAlpha": 0.1,
                        "label": vm.moyennetotalchiffre.toFixed(3),
                        "value": vm.moyennetotalchiffre,
                        "toValue": vm.moyennetotalchiffre,
                        "lineColor": "#ff0000",
                        "lineAlpha": 2,
                        "fillAlpha": 0.2,
                        "fillColor": "#FFCC00",
                        "inside": false
                    }]

                }],
                "startDuration": 1,
                "graphs": [{
                    "alphaField": "alpha",
                    "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
                    "fillAlphas": 1,
                    "title": "CA (TTC)",
                    "type": "column",
                    "valueField": "total_ttc",
                    "dashLengthField": "dashLengthColumn"
                }, {
                    "id": "graph2",
                    "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
                    "bullet": "round",
                    "lineThickness": 3,
                    "bulletSize": 7,
                    "bulletBorderAlpha": 1,
                    "bulletColor": "#FFFFFF",
                    "useLineColorForBulletBorder": true,
                    "bulletBorderThickness": 3,
                    "fillAlphas": 0,
                    "lineAlpha": 1,
                    "title": "Objectif",
                    "valueField": "objectif",
                    "dashLengthField": "dashLengthLine"
                }],
                "categoryField": "day",
                "categoryAxis": {
                    /*"gridPosition": "start",
                    "axisAlpha": 0,
                    "tickLength": 0*/

                    "gridPosition": "start",
                    "axisAlpha": 0,
                    "fillAlpha": 0.01, //default 0.05
                    "fillColor": "#000000",
                    "gridAlpha": 0,
                    "position": "bottom"
                },
                "export": {
                    "enabled": true
                }
            });

            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.kpiChartLineTauxSuccess = function (type = '') {
        WS.getScache('rapports/kpiChartLineTauxSuccess' + `?type=${type}&commercial=${vm.kpi_commercial}&month=${vm.month}&produit_code=${vm.kpi_produit}&gamme_code=${vm.kpi_gamme}`).then(function (response) {
            var data = [];
            response.data.visite.forEach(function (visite, indexVis) {

                response.data.vente.forEach(function (vente, indexVen) {
                    if (visite.date_visite == vente.date_visite) {
                        var obj = {};
                        obj = {
                            date_visite: vente.date_visite,
                            day: moment(vente.date_visite).format('DD ddd'),
                            taux: parseFloat(vente.nbr_vente * 100 / visite.nbr_total).toFixed(0)
                        }
                        data.push(obj);
                    }
                });
            });

            //traitement with kpi_dd global
            for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                i = (i <= 9) ? '0' + i : i;
                var date_trait = vm.month + '-' + i;
                var j = 0;
                data.forEach(function (element, index) {
                    if (element.date_visite == date_trait) {
                        j++;
                    }
                });
                if (j == 0) {
                    data.push({
                        date_visite: date_trait,
                        day: moment(date_trait).format('DD ddd'),
                        taux: 0
                    });
                }
            }
            vm.totalendtaux_success = data.reduce((s, f) => parseFloat(s) + parseFloat(f.taux), 0);

            vm.moyennetaux_success = vm.totalendtaux_success / data.length;
            //console.log(vm.totalendtaux_success, vm.moyennetaux_success );
            var data = _.sortBy(data, 'date_visite');

            var chart = AmCharts.makeChart("kpiChartLineTauxSuccess", {
                "type": "serial",
                "addClassNames": true,
                "theme": "light",
                "autoMargins": true,
                "balloon": {
                    "adjustBorderColor": false,
                    "horizontalPadding": 10,
                    "verticalPadding": 8,
                    "color": "#ffffff"
                },

                "dataProvider": data,
                "valueAxes": [{
                    "max": 100,
                    "maximum": 100,
                    "axisAlpha": 0,
                    "position": "left",
                    "guides": [{
                        "fillAlpha": 0.1,
                        "label": vm.moyennetaux_success.toFixed(2),
                        "value": vm.moyennetaux_success,
                        "toValue": vm.moyennetaux_success,
                        "lineColor": "#ff0000",
                        "lineAlpha": 2,
                        "fillAlpha": 0.2,
                        "fillColor": "#FFCC00",
                        "inside": false
                    }]

                }],
                "startDuration": 1,
                "graphs": [{
                    "alphaField": "alpha",
                    "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
                    "fillAlphas": 1,
                    "title": "Taux de succès (%)",
                    "type": "column",
                    "valueField": "taux",
                    "dashLengthField": "dashLengthColumn"
                }, {
                    "id": "graph2",
                    "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
                    "bullet": "round",
                    "lineThickness": 3,
                    "bulletSize": 7,
                    "bulletBorderAlpha": 1,
                    "bulletColor": "#FFFFFF",
                    "useLineColorForBulletBorder": true,
                    "bulletBorderThickness": 3,
                    "fillAlphas": 0,
                    "lineAlpha": 1,
                    "title": "Objectif",
                    "valueField": "objectif",
                    "dashLengthField": "dashLengthLine"
                }],
                "categoryField": "day",
                "categoryAxis": {
                    /*"gridPosition": "start",
                    "axisAlpha": 0,
                    "tickLength": 0*/

                    "gridPosition": "start",
                    "axisAlpha": 0,
                    "fillAlpha": 0.01, //default 0.05
                    "fillColor": "#000000",
                    "gridAlpha": 0,
                    "position": "bottom"
                },
                "export": {
                    "enabled": true
                }
            });
            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.kpiChartLineTauxVisites = function () {
        WS.getScache('rapports/kpiChartLineTauxVisites' + `?commercial=${vm.kpi_commercial}&month=${vm.month}&produit_code=${vm.kpi_produit}&gamme_code=${vm.kpi_gamme}`).then(function (response) {

            var objectifs = [];

            response.data.objectifs.forEach(function (element, index) {
                var date_debut = element.date_debut;
                var date_fin = element.date_fin;
                for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                    i = (i <= 9) ? '0' + i : i;
                    var compareDate = moment(vm.month + '-' + i, "YYYY-MM-DD");
                    var date_trait = moment(vm.month + '-' + i).format("YYYY-MM-DD");
                    var is_dim = moment(vm.month + '-' + i).format('dddd');
                    var day_obj = moment(vm.month + '-' + i).format('DD ddd');
                    var j = 0;
                    if (
                        (compareDate.isBetween(element.date_debut, element.date_fin) ||
                            compareDate.isSame(element.date_debut) ||
                            compareDate.isSame(element.date_fin)) && is_dim != 'dimanche') {
                        objectifs.forEach(function (objectif, indexObj) {
                            if (objectif.date_visite == date_trait) {
                                j++;
                                objectifs[indexObj].taux = parseFloat(objectif.taux) + element.obj_visite;
                            }
                        });
                        if (j == 0) {
                            objectifs.push({
                                taux: element.obj_visite,
                                date_visite: date_trait,
                                day: day_obj
                            });
                        }

                    } else {

                        objectifs.forEach(function (objectif, indexObj) {
                            if (objectif.date_visite == date_trait) {
                                j++;
                            }
                        });
                        if (j == 0) {
                            objectifs.push({
                                taux: 0,
                                date_visite: date_trait,
                                day: day_obj
                            });
                        }

                    }
                }

            });

            objectifs.forEach(function (element, index) {
                var i = 0;
                response.data.visite.forEach(function (visite, indexVis) {
                    if (element.date_visite == visite.date_visite) {
                        if (element.taux == 0 || visite.nbr_total == 0) {
                            var taux = 0;
                        } else {
                            var taux = visite.nbr_total * 100 / element.taux;
                            if (taux > 100) {
                                taux = 100;
                            }
                        }
                        objectifs[index].taux = taux.toFixed(0);
                        i++;
                    }
                });
                if (i == 0) {
                    objectifs[index].taux = 0;
                }
            });
            vm.totalendtaux_visite = objectifs.reduce((s, f) => parseFloat(s) + parseFloat(f.taux), 0);

            vm.moyennetaux_visite = vm.totalendtaux_visite / objectifs.length;
            //console.log(vm.totalendtaux_success, vm.moyennetaux_success );
            var chart = AmCharts.makeChart("kpiChartLineTauxVisites", {
                "type": "serial",
                "addClassNames": true,
                "theme": "light",
                "autoMargins": true,
                "balloon": {
                    "adjustBorderColor": false,
                    "horizontalPadding": 10,
                    "verticalPadding": 8,
                    "color": "#ffffff"
                },

                "dataProvider": objectifs,
                "valueAxes": [{
                    "max": 100,
                    "maximum": 100,
                    "axisAlpha": 0,
                    "position": "left",
                    "guides": [{
                        "fillAlpha": 0.1,
                        "label": vm.moyennetaux_visite.toFixed(2),
                        "value": vm.moyennetaux_visite,
                        "toValue": vm.moyennetaux_visite,
                        "lineColor": "#ff0000",
                        "lineAlpha": 2,
                        "fillAlpha": 0.2,
                        "fillColor": "#FFCC00",
                        "inside": false
                    }]

                }],
                "startDuration": 1,
                "graphs": [{
                    "alphaField": "alpha",
                    "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
                    "fillAlphas": 1,
                    "title": "Taux de Visite (%)",
                    "type": "column",
                    "valueField": "taux",
                    "dashLengthField": "dashLengthColumn"
                }],
                "categoryField": "day",
                "categoryAxis": {
                    /*"gridPosition": "start",
                    "axisAlpha": 0,
                    "tickLength": 0*/

                    "gridPosition": "start",
                    "axisAlpha": 0,
                    "fillAlpha": 0.01, //default 0.05
                    "fillColor": "#000000",
                    "gridAlpha": 0,
                    "position": "bottom"
                },
                "export": {
                    "enabled": true
                }
            });
            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.getVenteStatbyUser = function (user_code, date_vente) {
        //alert(user_code + ' ' +date_vente);
    };

    vm.getTotalVenteStat = function (date_vente) {
        //alert(date_vente);
    };


    /**
     *  ******************************************************* suiviCommercialMois ***************************************************************
     */

    $rootScope.$on('$translateChangeSuccess', function () {
        vm.allmonths = [
            '',
            $translate.instant('Janvier'), $translate.instant('Fevrier'),
            $translate.instant('Mars'), $translate.instant('Avril'),
            $translate.instant('Mai'), $translate.instant('Juin'),
            $translate.instant('Juillet'), $translate.instant('Aout'),
            $translate.instant('Septembre'), $translate.instant('Octobre'),
            $translate.instant('Novembre'), $translate.instant('Decembre'),
        ];

    });
    vm.initSuiviComMois = function () {
        vm.suivi = {};
        vm.suivi.commercial = '';
        vm.suivi.date_debut = moment().format('YYYY') + '-' + '01';
        vm.suivi.date_fin = moment().format('YYYY') + '-' + '12';
        vm.allmonths = [
            '',
            $translate.instant('Janvier'), $translate.instant('Fevrier'),
            $translate.instant('Mars'), $translate.instant('Avril'),
            $translate.instant('Mai'), $translate.instant('Juin'),
            $translate.instant('Juillet'), $translate.instant('Aout'),
            $translate.instant('Septembre'), $translate.instant('Octobre'),
            $translate.instant('Novembre'), $translate.instant('Decembre'),
        ];
        $(".datepicker-dashb_suiv_debut").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.suivi.date_debut = date;
                $scope.$apply();
                //console.log(vm.month);
            }
        });

        $(".datepicker-dashb_suiv_fin").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.suivi.date_fin = date;
                $scope.$apply();
                //console.log(vm.month);
            }
        });
    }

    vm.suiviCommericalParMois = function (suivi) {
        //console.log(suivi);
        vm.suivi_filter_month = [];
        WS.getData('rapports/suiviCommericalParMois', suivi)
            .then(response => {
                //console.log(response.data);
                vm.res_suivi = response.data;
                vm.linechartssuivi = [];
                response.data.forEach((element, index) => {
                    vm.suivi_filter_month.push(vm.allmonths[element.month] + ' - ' + element.year);
                    vm.linechartssuivi.push({
                        month: vm.allmonths[element.month] + ' - ' + element.year,
                        commande: element.chiffre_net_cmd,
                        bl: element.chiffre_net_bl,
                    });
                });
                //console.log(vm.linechartssuivi);
                AmCharts.makeChart("linechartssuivi", {
                    "type": "serial",
                    "theme": "light",
                    "decimalSeparator": ".",
                    "thousandsSeparator": "",
                    "legend": {
                        "useGraphSettings": true,
                        "valueWidth": 100,
                        //"width" : 350,
                    },
                    "dataProvider": vm.linechartssuivi,
                    "valueAxes": [{
                        "integersOnly": true,
                        "reversed": false,
                        "axisAlpha": 0,
                        "dashLength": 1,
                        "gridCount": 2,
                        "position": "left"
                        // "title": "Place taken"
                    }],
                    "startDuration": 0.5,
                    "graphs": [{
                        "balloonText": $translate.instant('commandes') + " [[category]]: [[value]]",
                        "bullet": "round",
                        "title": $translate.instant('commandes'),
                        "valueField": "commande",
                        "fillAlphas": 0
                    }, {
                        "balloonText": $translate.instant('bls') + " [[category]]: [[value]]",
                        "bullet": "round",
                        "title": $translate.instant('bls'),
                        "valueField": "bl",
                        "fillAlphas": 0
                    }],
                    "chartCursor": {
                        "cursorAlpha": 0,
                        "zoomable": false
                    },
                    "categoryField": "month",
                    "categoryAxis": {
                        "gridPosition": "start",
                        "axisAlpha": 0,
                        "fillAlpha": 0.05,
                        "fillColor": "#000000",
                        "gridAlpha": 0,
                        "position": "bottom"
                    },
                    "export": {
                        "enabled": true,
                        "position": "bottom-right"
                    }
                });
                $scope.$apply();
            }, error => {
                console.log(error);
            });
    };



    vm.getRapportVisitesParAnnee = function ($client, $year) {

        WS.get('visites/rapportVisiteParAnnee' + '/' + $client + '/' + $year)
            .then(function (response) {
                if (response.status == 200) {
                    vm.rapportvisitesparmois = response.data.visite;
                    vm.rapportblsparmois = response.data.bls;
                    vm.rapportblparmois = response.data.total;
                    vm.linechartssuivianne = [];

                    var tab_mois = new Array("",
                        "Janvier", "Fevrier", "Mars",
                        "Avril", "Mai", "Juin",
                        "Juillet", "Aout", "Septembre",
                        "Octobre", "Novembre", "Decembre"
                    );
                    response.data.total.forEach((element, index) => {
                        vm.linechartssuivianne.push({

                            month: tab_mois[element.mois],
                            // month : element.mois,
                            //commande : element.chiffre_net_cmd,
                            vente: element.total,
                        });
                    });
                    //console.log(vm.linechartssuivianne);
                    AmCharts.makeChart("linechartssuiviannee", {
                        "type": "serial",
                        "theme": "light",
                        "decimalSeparator": ".",
                        "thousandsSeparator": "",
                        "legend": {
                            "useGraphSettings": true,
                            "valueWidth": 100,
                            //"width" : 350,
                        },
                        "dataProvider": vm.linechartssuivianne,
                        "valueAxes": [{
                            "integersOnly": true,
                            "reversed": false,
                            "axisAlpha": 0,
                            "dashLength": 1,
                            "gridCount": 2,
                            "position": "left"
                            // "title": "Place taken"
                        }],
                        "startDuration": 0.5,
                        "graphs": [{
                            "balloonText": $translate.instant('Vente') + " [[category]]: [[value]]",
                            "bullet": "round",
                            "title": $translate.instant('Vente'),
                            "valueField": "vente",
                            "fillAlphas": 0
                        }],
                        "chartCursor": {
                            "cursorAlpha": 0,
                            "zoomable": false
                        },
                        "categoryField": "month",
                        "categoryAxis": {
                            "gridPosition": "start",
                            "axisAlpha": 0,
                            "fillAlpha": 0.05,
                            "fillColor": "#000000",
                            "gridAlpha": 0,
                            "position": "bottom"
                        },
                        "export": {
                            "enabled": true,
                            "position": "bottom-right"
                        }
                    });
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectRoutes = function () {
        WS.get('routing/selectRouteWithDepotUser')
            .then(function (response) {
                vm.routings = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getcategories = function () {
        WS.get('categoriescommerciale')
            .then(function (response) {
                if (response.status == 200) {

                    vm.categories = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.selectGouvernorats = () => {
        WS.get('gouvernorats/selectGouvernorats')
            .then(function (response) {
                vm.gouvernorats = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.suiviClientsByYear = function (suivi_cls) {
        WS.getData('rapports/suiviClientsByYear', suivi_cls)
            .then(response => {
                vm.selectedyears = [];
                var res_year = _.groupBy(response.data, 'year');
                vm.suivi_clsbyyear = _.groupBy(response.data, 'code');

                _.each(res_year, (element, index) => {
                    vm.selectedyears.push(index);
                })
                $scope.$apply();
            });
    };

    vm.getListCheques = function (suivi_cls) {
        WS.getData('rapports/suivilistCheques', suivi_cls)
            .then(response => {
                vm.suivilistCheques = response.data;
                $scope.$apply();
            });
    };

    vm.exportSuiviCheque = function (suivi_cls) {
        WS.getData('rapports/suivilistCheques', suivi_cls)
            .then(response => {
                var filename = 'Suivi List Cheques-' + moment().format("DD-MM-YYYY_HH:mm:ss");

                var file = {
                    sheetid: 'Suivi List Cheques--' + moment().format("DD-MM-YYYY HH:mm:ss"),
                    headers: true,
                    caption: {
                        title: 'Suivi List Cheques--' + moment().format("DD-MM-YYYY HH:mm:ss"),
                        style: 'font-size: 50px; color:blue;'
                    },
                    column: {
                        style: 'font-size:20px;background:#d1d1d1;'
                    },
                    columns: [

                        { columnid: 'Commercial', title: 'Commercial' },
                        { columnid: 'Client', title: 'Client' },
                        { columnid: 'date', title: 'BL' },
                        { columnid: 'code_bl', title: 'Mobile' },
                        { columnid: 'recouvrement_code', title: 'Recouvrement' },
                        { columnid: 'montant', title: 'Montant Total' },
                        { columnid: 'cheque', title: 'Montant Cheque' },
                        { columnid: 'num_cheque', title: 'Numero' },
                        { columnid: 'banque_cheque', title: 'Banque' },
                        { columnid: 'date_echeance_cheque', title: 'Echeance' }

                    ],
                    row: {
                        style: function (sheet, row, rowidx) {
                            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                        }
                    }
                };
                alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
            },
                function (error) {
                    console.log(error);
                }
            );
    };


    vm.getsuiviCommercialClient = function (suivi_cls) {
        WS.getData('rapports/suiviCommercialClient', suivi_cls)
            .then(response => {
                vm.suiviCommercialClient = response.data;
                $scope.$apply();
            });
    };


    vm.getcreditencours = function (filter) {
        WS.getData('rapports/creditEncoursParCommercialForAnalyse', filter)
            .then(response => {
                vm.creditencours = response.data;
                $scope.$apply();
            });
    };

    vm.getcreditencoursParClient = function (filter) {
        WS.getData('rapports/creditEncoursParClientForAnalyse', filter)
            .then(response => {
                vm.listcreditClient = response.data;
                $scope.$apply();
            });
    };


    // vm.exportcreditencourse = function (commercial) {
    //     WS.getData('rapports/creditEncoursParClientForAnalyse', commercial)
    //         .then(response => {
    //             var count = response.data.length;
    //             var filename = 'Credit Encours-' + moment().format("DD-MM-YYYY_HH:mm:ss");
    //             // var filename = response.data[count - 1].type + '-' + response.data[count - 1].date_visite;

    //             var file = {
    //                 sheetid: 'Credit Encours-' + moment().format("DD-MM-YYYY HH:mm:ss"),
    //                 headers: true,
    //                 caption: {
    //                     title: 'Credit Encours-' + moment().format("DD-MM-YYYY HH:mm:ss"),
    //                     style: 'font-size: 50px; color:blue;'
    //                 },
    //                 column: {
    //                     style: 'font-size:20px;background:#d1d1d1;'
    //                 },
    //                 columns: [

    //                     { columnid: 'code', title: 'Code' },
    //                     { columnid: 'client', title: 'Client' },
    //                     { columnid: 'magasin', title: 'Magasin' },
    //                     { columnid: 'adresse_facturation', title: 'Adresse' },
    //                     { columnid: 'mobile', title: 'Mobile' },
    //                     { columnid: 'routing_code', title: 'Route' },
    //                     { columnid: 'encours', title: 'Encours' }

    //                 ],
    //                 row: {
    //                     style: function (sheet, row, rowidx) {
    //                         return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
    //                     }
    //                 }
    //             };
    //             response.data.forEach(function (element, index) {
    //                 response.data[index].net_a_payer = response.data[index].net_a_payer.toString().replace('.', ',');
    //             });
    //             alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
    //         },
    //             function (error) {
    //                 console.log(error);
    //             }
    //         );
    // };

    vm.exportcreditencoursParClient = function (filter) {
        WS.getData('rapports/creditEncoursParClientForAnalyse', filter)
            .then(response => {
                var count = response.data.length;
                var filename = 'Credit Encours-' + moment().format("DD-MM-YYYY_HH:mm:ss");
                // var filename = response.data[count - 1].type + '-' + response.data[count - 1].date_visite;

                var file = {
                    sheetid: 'Credit Encours-' + moment().format("DD-MM-YYYY HH:mm:ss"),
                    headers: true,
                    // caption: {
                    //     title: 'Credit Encours-' + moment().format("DD-MM-YYYY HH:mm:ss"),
                    //     style: 'font-size: 50px; color:blue;'
                    // },
                    column: {
                        style: 'font-size:20px;background:#d1d1d1;'
                    },
                    columns: [

                        { columnid: 'code', title: 'Code' },
                        { columnid: 'client', title: 'Client' },
                        { columnid: 'magasin', title: 'Magasin' },
                        { columnid: 'adresse_facturation', title: 'Adresse' },
                        { columnid: 'mobile', title: 'Mobile' },
                        { columnid: 'routing_code', title: 'Route' },
                        { columnid: 'encours', title: 'Encours' }

                    ],
                    row: {
                        style: function (sheet, row, rowidx) {
                            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                        }
                    }
                };
                // response.data.forEach(function (element, index) {
                //     response.data[index].net_a_payer = response.data[index].net_a_payer.toString().replace('.', ',');
                // });
                alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
            },
                function (error) {
                    console.log(error);
                }
            );
    };

    vm.exportcreditencours = function (filter) {
        WS.getData('rapports/creditEncoursParCommercialForAnalyse', filter)
            .then(response => {
                var filename = 'Credit Encours-' + moment().format("DD-MM-YYYY_HH:mm:ss");

                var file = {
                    sheetid: 'Credit Encours-' + moment().format("DD-MM-YYYY HH:mm:ss"),
                    headers: true,
                    // caption: {
                    //     title: 'Credit Encours-' + moment().format("DD-MM-YYYY HH:mm:ss"),
                    //     style: 'font-size: 50px; color:blue;'
                    // },
                    column: {
                        style: 'font-size:20px;background:#d1d1d1;'
                    },
                    columns: [

                        { columnid: 'code', title: 'Code' },
                        { columnid: 'client', title: 'Client' },
                        { columnid: 'magasin', title: 'Magasin' },
                        { columnid: 'adresse_facturation', title: 'Adresse' },
                        { columnid: 'mobile', title: 'Mobile' },
                        { columnid: 'routing_code', title: 'Route' },
                        { columnid: 'commercial', title: 'Commercial' },
                        { columnid: 'encours', title: 'Encours' }

                    ],
                    row: {
                        style: function (sheet, row, rowidx) {
                            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                        }
                    }
                };
                alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
            },
                function (error) {
                    console.log(error);
                }
            );
    };



    vm.getLastvisiteDetails = function (client) {
        WS.get('clients/getLastVisite/' + client, vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.getLastVisite = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }




    /**
     * suivi commande par produits
     */

    vm.suiv_date_debut = moment().format('YYYY-MM-DD');
    vm.suiv_date_fin = moment().format('YYYY-MM-DD');
    vm.cmdByProduitCommercial = function () {
        var data = {
            date_debut: vm.suiv_date_debut,
            date_fin: vm.suiv_date_fin,
        };
        WS.getData('gestion_commandes/cmdByProduitCommercial', data).then(
            function (response) {
                console.log(response.data);
                vm.table_suivi = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log('error : ->', error);
            }
        );
    };

    vm.exporttableHtmlSuiv = function (tableId) {
        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;
        var title = "Rapport-";
        title += vm.date_debut + '_||_' + vm.date_fin;

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);

    };

    vm.ExportcmdByProduitCommercial = function () {
        var url = `gestion_commandes/ExportcmdByProduitCommercial?date_debut=${vm.suiv_date_debut}&date_fin=${vm.suiv_date_fin}`;
        $window.location.href = AppSettings.apiUrl + url;
    };

    vm.getInfoCommercial = function (code) {
        vm.infouser = _.findWhere(vm.selectusers, { code: code });
        //console.log(vm.infouser);
    }

    vm.exportTableTExcelFile = function (tableId, title, check_type) {
        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;

        download_link.download = title + vm.infouser.user + '-' + check_type + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);

    };




    vm.getProdsByClients = function () {
        var data = {
            type: 'commande',
            date: vm.select_date,
            commercial: vm.select_commercial,
            gamme: vm.select_gamme,
            famille: vm.select_famille,
            sousfamille: vm.select_sousfamille,
        };
        WS.getData('rapports/getProdsByClients', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.listProd = response.data.listProd;
                    vm.listClient = response.data.listClient;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getTournee = function (code, date = null) {
        WS.get('tournees/getRouteTourneeClientByCommercial/' + code + '/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.tournees = response.data.tournees;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.getClientNonTraiter = function (code, date) {
        WS.get('tournees/getClientNonTraiter/' + code + '/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.tournees_non_traite = response.data.tournees;
                    //console.log(code, date, vm.tournees_non_traite);
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getclientIntourne = function (code, liste) {

        if (!liste) return false;
        for (var i = 0; i < liste.length; i++) {
            if (liste[i]['client_code'] == code) {
                return true;
            }
        }
    };


    vm.selectedUser = (user_code) => {
        vm.currentUser = _.find(vm.commercial, (memo) => memo.code == user_code);

        //console.log("currentUser" , vm.currentUser);
    }

    vm.ExportRapportTournee = function (tableId) {

        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;
        var title = 'Rapport-tournee ' + vm.currentUser.code + ' ' + vm.currentUser.nom + ' ' + vm.currentDate;

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);

    };


    /**
     * vente by month
     */

    $(document).ready(function () {
        $(".datepicker-year").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.vente_by_month.year = date;
                $scope.$apply();
            }
        });


        $(".stat_assenceur_day").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.stat_assenceur.date = date;
                $scope.$apply();
            }
        });

        $(".sf_promo_gr_debut").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.sf_promo_gratuite.date_debut = date;
                $scope.$apply();
            }
        });

        $(".sf_promo_gr_fin").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.sf_promo_gratuite.date_fin = date;
                $scope.$apply();
            }
        });


        $(".dates_debut").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.filterdate.date_debut = date;
                $scope.$apply();
            }
        });

        $(".dates_fin").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.filterdate.date_fin = date;
                $scope.$apply();
            }
        });
    })

    vm.findVenteByMonth = () => {
        
        WS.post('rapports/findVenteByMonth', vm.vente_by_month)
            .then(function (response) {
                vm.formated_ventes = _.groupBy(response.data, function (num) { return num.code });

                vm.total_months_prod = {};
                _.each(vm.formated_ventes, (element, code) => {
                    vm.total_months_prod[code] = _.reduce(element, function (memo, num) { return memo + num.value; }, 0);
                });
                var months = _.pluck(response.data, 'month');
                vm.months_nambers = _.uniq(months);
                vm.total_months = {};

                _.each(vm.months_nambers, element => {
                    var tab_month = _.filter(response.data, function (num) { return num.month == element; });
                    vm.total_months[element] = _.reduce(tab_month, function (memo, num) { return memo + num.value; }, 0);
                });

                vm.somme_from_all = _.reduce(vm.total_months_prod, function (memo, num) { return memo + num; }, 0);
                //vm.month_names
                // console.log(vm.formated_ventes, vm.months_nambers);
                $scope.$apply();
            })
            .then(null, function (error) {

            });
    }


    vm.calculateTotalofSF = (sousfamille_code) => {
        var res = 0;
        _.each(vm.rapportventesousfamille, element => {
            if (element.code == sousfamille_code) {
                res = res + element.value;
            }
        });
        return res;
    };

    vm.calculateTotalofClient = (client_code) => {
        var res = 0;
        _.each(vm.rapportventesousfamille, element => {
            if (element.client_code == client_code) {
                res = res + element.value;
            }
        });
        return res;
    };


    vm.findAssenceurs = function () {
        WS.getData('rapports/findAssenceurs', vm.stat_assenceur)
            .then(function (response) {
                if (response.status == 200) {
                    vm.assenceurs = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.findDetailsAssenceur = function (assenceur_code) {
        vm.stat_assenceur.assenceur_code = assenceur_code;
        WS.getData('rapports/findDetailsAssenceur', vm.stat_assenceur)
            .then(function (response) {
                if (response.status == 200) {
                    vm.details_assenceur = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getListClientsByUser = (user_code, filter) => {
        console.log(user_code, filter);
        var data = {
            user_code: user_code,
            year: filter.year,
            month: filter.month,
            day: filter.day
        }

        WS.getData('rapports/getListClientsByUser', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.listClientsVisite = response.data.visites;
                    vm.listClientsNonVisite = response.data.nonVisites;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.exportClientsVisitee = function () {
        var user = (vm.filter.user_code) ? vm.filter.user_code : '';
        var day = (vm.filter.day) ? vm.filter.day : '';
        var filename = "Clients visités-" + user + '-'
            + vm.filter.year + '-'
            + vm.filter.month + '-'
            + day;

        var file = {
            sheetid: filename,
            headers: true,
            // caption: {
            //     title: filename,
            //     style: 'font-size: 50px; color:blue;'
            // },
            column: {
                style: 'font-size:20px;background:#d1d1d1;'
            },
            columns: [
                { columnid: 'code', title: 'Code' },
                { columnid: 'client', title: 'Client' },
                { columnid: 'magasin', title: 'Magasin' },
                { columnid: 'adresse', title: 'Adresse' },
                { columnid: 'credit', title: 'Crédit' },
                { columnid: 'last_visite', title: 'Dernier visite user' },
                { columnid: 'last_visite_client', title: 'Dernier visite client' }
            ],
            row: {
                style: function (sheet, row, rowidx) {
                    return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                }
            }
        };
        alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, vm.listClientsVisite]);
    }

    vm.exportClientsNonVisitee = function () {
        var user = (vm.filter.user_code) ? vm.filter.user_code : '';
        var day = (vm.filter.day) ? vm.filter.day : '';
        var filename = "Clients non visités-" + user + '-'
            + vm.filter.year + '-'
            + vm.filter.month + '-'
            + day;

        var file = {
            sheetid: filename,
            headers: true,
            // caption: {
            //     title: filename,
            //     style: 'font-size: 50px; color:blue;'
            // },
            column: {
                style: 'font-size:20px;background:#d1d1d1;'
            },
            columns: [
                { columnid: 'code', title: 'Code' },
                { columnid: 'client', title: 'Client' },
                { columnid: 'magasin', title: 'Magasin' },
                { columnid: 'adresse', title: 'Adresse' },
                { columnid: 'credit', title: 'Crédit' },
                { columnid: 'last_visite', title: 'Dernier visite user' },
                { columnid: 'last_visite_client', title: 'Dernier visite client' }
            ],
            row: {
                style: function (sheet, row, rowidx) {
                    return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                }
            }
        };
        alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, vm.listClientsNonVisite]);
    }

    vm.selectListClientByPaginate = (page = 1, per_page = 15) => {
        var data = {
            page,
            per_page,
            searchbox: vm.searchbox
        };
        WS.getData('clients/selectListClientByPaginate', data)
            .then((response) => {
                vm.list_clients = response.data.data;
                vm.current_page = response.data.current_page;
                vm.previous = vm.current_page - 1;
                vm.next = vm.current_page + 1;
                vm.from = response.data.form;
                vm.last_page = response.data.last_page;
                vm.next_page_url = response.data.next_page_url;
                vm.per_page = response.data.per_page;
                vm.prev_page_url = response.data.prev_page_url;
                vm.to = response.data.to;
                vm.total = response.data.total;
                vm.paginate = [];
                for (var i = 1; i <= vm.last_page; i++) {
                    vm.paginate.push(i);
                }
                //console.log(vm.list_clients);
                $scope.$apply();
            }, (error) => {

            })
    };
    vm.checkpaginate = function (current, page, lastpage) {
        if (page > current + 3 || page < current - 3) {
            return false;
        } else {
            return true;
        }
    }




    vm.sfPromoGratuite = () => {
        vm.loading_request = true;

        WS.getData('rapports/sfPromoGratuite', vm.sf_promo_gratuite)
            .then(function (response) {
                if (response.status == 200) {
                    vm.sf_promo_res = response.data;
                    vm.total_with_promo = 0;
                    vm.total_without_promo = 0;
                    vm.total_gratuite = 0;
                    vm.total_ca_en_promotion = 0;
                    vm.total_ca_hors_promotion = 0;
                    _.each(response.data, element => {
                        vm.total_with_promo += element.with_promo;
                        vm.total_without_promo += element.without_promo;
                        vm.total_gratuite += element.gratuite;
                        vm.total_ca_en_promotion += element.ca_en_promotion;
                        vm.total_ca_hors_promotion += element.ca_hors_promotion;
                    });
                    vm.loading_request = false;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.getTourneeByInterval = () => {
        WS.getData('rapports/getTourneeByInterval', vm.filterdate)
            .then(function (response) {
                if (response.status == 200) {
                    vm.stat_tournees = response.data;
                    vm.total_bls = 0;
                    vm.total_commandes = 0;
                    _.each(vm.stat_tournees.bls, dates => {
                        _.each(dates, bl => {
                            vm.total_bls += bl.net_a_payer;
                        })
                    })

                    _.each(vm.stat_tournees.commandes, dates => {
                        _.each(dates, bl => {
                            vm.total_commandes += bl.net_a_payer;
                        })
                    })
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.calculateMontant = (bls) => {
        var montant = 0;
        var in_tournee = 0;
        var out_tournee = 0;
        _.each(bls, element => {
            montant += element.net_a_payer;
            in_tournee += (element.in_tournee == '1') ? 1 : 0;
            out_tournee += (element.in_tournee == '0') ? 1 : 0;
        })

        return {
            montant,
            in_tournee,
            out_tournee

        };
    }

    vm.getReglement = () => {
        WS.getData('rapports/getReglement', vm.filterdate)
            .then(function (response) {
                if (response.status == 200) {
                    vm.reglements = response.data;
                    vm.total_reglement = 0;
                    _.each(vm.reglements, element => {
                        vm.total_reglement += element.montant;
                    })
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getReclamations = () => {
        var data = {
            date_debut: vm.filterdate.date_debut,
            date_fin: vm.filterdate.date_fin,
            user_code: vm.filterdate.user_code,
            theme: 'RECLAMATION'
        }
        WS.getData('rapports/getNotesByTheme', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.reclamations = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getDemandes = () => {
        var data = {
            date_debut: vm.filterdate.date_debut,
            date_fin: vm.filterdate.date_fin,
            user_code: vm.filterdate.user_code,
            theme: 'DEMANDES'
        }
        WS.getData('rapports/getNotesByTheme', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.demandes = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getVeilleConcurentiel = () => {
        var data = {
            date_debut: vm.filterdate.date_debut,
            date_fin: vm.filterdate.date_fin,
            user_code: vm.filterdate.user_code,
            theme: 'VEILLE CONCURRENTIELLE'
        }
        WS.getData('rapports/getNotesByTheme', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.veille_concurenciel = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getInformationNotes = () => {
        var data = {
            date_debut: vm.filterdate.date_debut,
            date_fin: vm.filterdate.date_fin,
            user_code: vm.filterdate.user_code,
            theme: 'INFORMATION'
        }
        WS.getData('rapports/getNotesByTheme', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.informations = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.NvClients = () => {
        var data = {
            date_debut: vm.filterdate.date_debut,
            date_fin: vm.filterdate.date_fin,
            user_code: vm.filterdate.user_code
        }
        WS.getData('rapports/NvClients', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.nv_clients = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getTourneeByIntervalUsers = () => {
        var data = {
            date_debut: vm.filterdate.date_debut,
            date_fin: vm.filterdate.date_fin,
            user_code: vm.filterdate.user_code
        }
        WS.getData('rapports/getTourneeByIntervalUsers', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.users_rapports = response.data;
                    //console.log(vm.users_rapports);
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.exportTourneeByIntervalUsers = () => {
        var data = {
            date_debut: vm.filterdate.date_debut,
            date_fin: vm.filterdate.date_fin,
            user_code: vm.filterdate.user_code
        }
        WS.getData('rapports/exportTourneeByIntervalUsers', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.visites_per_user = response.data;
                    console.log(vm.visites_per_user);

                    var filename = 'reporting-visites-' + vm.filterdate.date_debut + '_' + vm.filterdate.date_fin + '_' + vm.filterdate.user_code;

                    var file = {
                        sheetid: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
                        headers: true,
                        caption: {
                            title: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
                            style: 'font-size: 50px; color:blue;'
                        },
                        column: {
                            style: 'font-size:20px;background:#d1d1d1;'
                        },
                        columns: [

                            { columnid: 'code', title: 'Code' },
                            { columnid: 'user', title: 'User' },
                            { columnid: 'depot', title: 'Dépot' },
                            { columnid: 'total_visite_planifiee', title: 'PROGRAMME' },
                            { columnid: 'total_client_visite_intourne', title: 'PROGRAMME VISITE' },
                            { columnid: 'cls_programme_not_visite', title: 'PROGRAMME NON VISITE' },
                            { columnid: 'total_client_visite_outtourne', title: 'VISITE HORS TOURNEE' },
                            { columnid: 'total_client_visite', title: 'TOTAL VISITES' },
                            // { columnid: 'total_client_visite', title: 'REUSSITE TOURNEE' },
                            { columnid: 'total_visite_non_livre', title: 'VISITES NON LIVRES' },
                            { columnid: 'total_bls', title: 'NB BONS DE LIVRAISON' },
                            { columnid: 'total_avoirs', title: 'NB AVOIRS' },
                            { columnid: 'total_commandes', title: 'NB COMMANDES' },
                            { columnid: 'nvClients', title: 'NOBRE DE CREATION CLIENTS' },
                            { columnid: 'cls_sans_tournee', title: 'NB CLIENTS SANS TOURNEE' },



                        ],
                        row: {
                            style: function (sheet, row, rowidx) {
                                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                            }
                        }
                    };
                    alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }



    /**
     * Rapport 39
     */

    vm.getActivites = function () {
        WS.get('activites', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.activites = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    
    vm.getTypeClients = function () {
        WS.get('type_clients')
            .then(function (response) {
                if (response.status == 200) {
                    vm.type_clients = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getSpecialites = function () {
        WS.get('specialites')
            .then(function (response) {
                if (response.status == 200) {
                    vm.specialites = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    

    vm.getTourneeByRegion = () => {
        var data = {
            date_debut: vm.filterdate.date_debut,
            date_fin: vm.filterdate.date_fin,
            region_code: vm.filterdate.region_code
        }
        WS.getData('rapports/getTourneeByRegion', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.users_rapports = response.data;
                    vm.tournees_zone = response.data.tournees;
                    vm.visites_zone = response.data.visites;
                    console.log(vm.tournees_zone);
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }




    vm.changeSelectedUsers = () => {
        vm.selected_commercials = [];
        vm.commercial
        var ths = `<th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>`;
        _.each(vm.filterdate.user_codes, element => {
            _.each(vm.commercial, commercial => {
                if (element == commercial.code) {
                    vm.selected_commercials.push(commercial);
                    ths += `
                        <th class="text-center" title="visite planifier" style="border-left: 2px solid #000">P</th>
                        <th class="text-center" title="total visites">V</th>
                        <th class="text-center" title="visite planifier">VP</th>
                        <th class="text-center" title="planifier non visites">R</th>
                        <th class="text-center" title="visite hors tournee">H</th>
                        <th class="text-center" title="Non planifié non visité">NN</th>
                        <th class="text-center" title="taux de couverture (V / nbr de clients)">TC</th>
                        <th class="text-center" title="respect tournee (VP/P)">TR</th>
                    `;
                }
            })
        })
        ths += `
            <th class="text-center" title="visite planifier" style="border-left: 2px solid #000">P</th>
            <th class="text-center" title="total visites">V</th>
            <th class="text-center" title="visite planifier">VP</th>
            <th class="text-center" title="planifier non visites">R</th>
            <th class="text-center" title="visite hors tournee">H</th>
            <th class="text-center" title="Non planifié non visité">NN</th>
            <th class="text-center" title="taux de couverture (V / nbr de clients)">TC</th>
            <th class="text-center" title="respect tournee (VP/P)" style="border-right: 2px solid #000">TR</th>
        `;
        vm.ths = vm.trustHtml(ths);
    }

    vm.trustHtml = function (html) {
        return $sce.trustAsHtml(html);
    };

    vm.getTourneeByRegionUser = () => {
        vm.loading_request = true;
        var data = {
            date_debut: vm.filterdate.date_debut,
            date_fin: vm.filterdate.date_fin,
            region_code: vm.filterdate.region_code,
            user_codes: vm.filterdate.user_codes,
            activite_codes: vm.filterdate.activite_codes,
            specialites: vm.filterdate.specialites,
            typeclients_codes : vm.filterdate.typeclients_codes
        }
        WS.post('rapports/getTourneeByRegionUser', data)
            .then(function (response) {
                if (response.status == 200) {
                    console.log(response.data);
                    vm.tournee_activites = response.data.tournees;

                    vm.total_tournees = response.data.total_tournees;

                    vm.total_visites = response.data.total_visites;

                    vm.visites_in_tournees = response.data.visites_in_tournees;

                    vm.visites_out_tournees = response.data.visites_out_tournees;

                    vm.programme_non_visite = response.data.programme_non_visite;

                    vm.non_visite_non_planifie = response.data.non_visite_non_planifie;

                    vm.nbr_cls = response.data.nbr_cls;

                    //total tournees : 
                    /**
                     * create all tbodys table
                     */
                    console.log('called method getAlltbodys');




                    vm.getAlltbodys();


                    $scope.$apply();

                } else { }
            })
            .then(null, function (error) {
                vm.loading_request = false;
                console.log(error);
            });
    }

    vm.convertToNumber = (number) => {
        if(number !== "undefined" && number !== undefined){
            var number = Number(number);
            if (isNaN(number)) {
                return 0;
            } else {
                return Number(number);
            }
        }else{
            return 0;
        }
        
    }
    vm.getAlltbodys = () => {
        //console.log('called method getAlltbodys success 1');

        var tbodys = '';
        //if(vm.total_tournees.length>0){
            //console.log('called method getAlltbodys success 2');

            _.each(vm.tournee_activites,( zones, activite_code) => {
                tbodys += ``;
                _.each(zones , (zone, zone_code) => {
                    tbodys += `<tr>
                    <td class="text-center" style="background: antiquewhite;">${activite_code }</td>
                    <td class="text-center" style="background: antiquewhite;">${ zone_code }</td>
                    <td class="text-center" style="background: antiquewhite;">
                        <a ng-click="vm.getClientByZoneActivite('${zone_code}', '${activite_code}');"
                           data-toggle="modal" data-target=".rapport_40_modal">
                        ${vm.convertToNumber(vm.nbr_cls[activite_code + '_'+ zone_code])}
                        </a>
                    </td>`;

                    
                        _.each(vm.selected_commercials, com => {
                            var index_activite = activite_code + '_' + zone_code + '_' + com.code;
                            tbodys += `
                                <td class="text-center" style="border-left: 2px solid #000"> 
                                    <a ng-click="vm.detailsTourneesPlanifie('${zone_code}', '${activite_code}', '${com.code}')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                        ${vm.convertToNumber(vm.total_tournees[index_activite])}
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsVisitesRap40('${zone_code}', '${activite_code}', '${com.code}', 'all')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${vm.convertToNumber(vm.total_visites[index_activite]) }
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsVisitesRap40('${zone_code}', '${activite_code}', '${com.code}', 'in_tournee')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                        ${vm.convertToNumber(vm.visites_in_tournees[index_activite])}
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsPlanifieNonVisite('${zone_code}', '${activite_code}', '${com.code}')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${vm.convertToNumber(vm.programme_non_visite[index_activite])}
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsVisitesRap40('${zone_code}', '${activite_code}', '${com.code}', 'out_tournee')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${vm.convertToNumber(vm.visites_out_tournees[index_activite])}
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsNVisiteNPlanifie('${zone_code}', '${activite_code}', '${com.code}')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${vm.convertToNumber(vm.non_visite_non_planifie[index_activite])}
                                    </a>
                                </td>
                                <td class="text-center" title="visites / nbr de clients">
                                        ${ vm.convertToNumber(vm.total_visites[index_activite] * 100 / vm.convertToNumber(vm.nbr_cls[activite_code + '_'+ zone_code])).toFixed(0)} %
                                </td>
                                <td class="text-center">
                                    ${vm.convertToNumber(vm.total_tournees[index_activite]) ? (vm.convertToNumber(vm.visites_in_tournees[index_activite]) * 100 / vm.convertToNumber(vm.total_tournees[index_activite])).toFixed(0) : 0} %
                                </td>`;
                        });
                        var total_activite_zone = activite_code  + '_' + zone_code;
                        tbodys +=`
                                <td class="text-center"  style="border-left: 2px solid #000;">
                                    <a ng-click="vm.detailsTourneesPlanifie('${zone_code}', '${activite_code}', '')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${ vm.total_tournees[total_activite_zone] ? vm.convertToNumber(vm.total_tournees[total_activite_zone]) : 0 }
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsVisitesRap40('${zone_code}', '${activite_code}', '', 'all')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${ vm.convertToNumber(vm.total_visites[total_activite_zone]) }
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsVisitesRap40('${zone_code}', '${activite_code}', '', 'in_tournee')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${ vm.convertToNumber(vm.visites_in_tournees[total_activite_zone]) }
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsPlanifieNonVisite('${zone_code}', '${activite_code}', '')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${ vm.convertToNumber(vm.programme_non_visite[total_activite_zone]) }
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsVisitesRap40('${zone_code}', '${activite_code}', '', 'out_tournee')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${ vm.convertToNumber(vm.visites_out_tournees[total_activite_zone]) }
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsNVisiteNPlanifie('${zone_code}', '${activite_code}', '')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${ vm.convertToNumber(vm.non_visite_non_planifie[total_activite_zone]) }
                                    </a>
                                </td>
                                <td class="text-center">
                                    ${ (vm.convertToNumber(vm.total_visites[total_activite_zone]) *100 / vm.convertToNumber(vm.nbr_cls[total_activite_zone])).toFixed(0) } %
                                </td>
                                <td class="text-center" style="border-right: 2px solid #000;">
                                    ${vm.convertToNumber(vm.total_tournees[total_activite_zone]) ? (vm.convertToNumber(vm.visites_in_tournees[total_activite_zone]) * 100 / vm.convertToNumber(vm.total_tournees[total_activite_zone])).toFixed(0) : 0} %
                                </td>
                            </tr>`;
                })
                tbodys += `<tr  style="border-bottom: 2px solid #000;">
                    <td class="text-center" colspan="2" style="background: aquamarine;">Total</td>
                    <td class="text-center" style="background: aquamarine;"> ${vm.nbr_cls[activite_code]}  </td>`
                    _.each(vm.selected_commercials, com => {
                        var total_activite_commercial = activite_code  + '_' + com.code;
                        tbodys +=`<td class="text-center"  style="border-left: 2px solid #000;background: aquamarine;">
                                    <a ng-click="vm.detailsTourneesPlanifie('', '${activite_code}', '${com.code}')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                        ${ vm.convertToNumber(vm.total_tournees[total_activite_commercial]) }
                                    </a>
                                </td>
                            <td class="text-center"style="background: aquamarine;">
                                <a ng-click="vm.detailsVisitesRap40('', '${activite_code}', '${com.code}', 'all')"
                                    data-toggle="modal" data-target=".rapport_40_modal">
                                    ${ vm.convertToNumber(vm.total_visites[total_activite_commercial])}
                                </a>
                            </td>
                            <td class="text-center"style="background: aquamarine;">
                                <a ng-click="vm.detailsVisitesRap40('', '${activite_code}', '${com.code}', 'in_tournee')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                ${ vm.convertToNumber(vm.visites_in_tournees[total_activite_commercial]) }
                                </a>
                            </td>
                            <td class="text-center"style="background: aquamarine;">
                                <a ng-click="vm.detailsPlanifieNonVisite('', '${activite_code}', '${com.code}')"
                                    data-toggle="modal" data-target=".rapport_40_modal">
                                ${ vm.convertToNumber(vm.programme_non_visite[total_activite_commercial]) }
                                </a>
                            </td>
                            <td class="text-center"style="background: aquamarine;">
                                <a ng-click="vm.detailsVisitesRap40('', '${activite_code}', '${com.code}', 'out_tournee')"
                                    data-toggle="modal" data-target=".rapport_40_modal">
                                ${ vm.convertToNumber(vm.visites_out_tournees[total_activite_commercial]) }
                                </a>
                            </td>
                            <td class="text-center"style="background: aquamarine;">
                                <a ng-click="vm.detailsNVisiteNPlanifie('', '${activite_code}', '${com.code}')"
                                    data-toggle="modal" data-target=".rapport_40_modal">
                                ${ vm.convertToNumber(vm.non_visite_non_planifie[total_activite_commercial]) }
                                </a>
                            </td>
                            <td class="text-center"style="background: aquamarine;">
                            ${ (vm.convertToNumber(vm.total_visites[total_activite_commercial]) *100 / vm.convertToNumber(vm.nbr_cls[activite_code])).toFixed(0) } %
                            </td>
                            <td class="text-center"style="background: aquamarine;">
                                ${vm.convertToNumber(vm.total_tournees[total_activite_commercial]) ? (vm.convertToNumber(vm.visites_in_tournees[total_activite_commercial]) * 100 / vm.convertToNumber(vm.total_tournees[total_activite_commercial])).toFixed(0) : 0} %
                            </td>`
                    });
                    tbodys +=`
                        <td class="text-center" style="border-left: 2px solid #000;background: aquamarine;">
                            <a ng-click="vm.detailsTourneesPlanifie('', '${activite_code}', '')"
                                data-toggle="modal" data-target=".rapport_40_modal">
                            ${ vm.convertToNumber(vm.total_tournees[activite_code]) }
                            </a>
                        </td>
                        <td class="text-center"style="background: aquamarine;">
                            <a ng-click="vm.detailsVisitesRap40('', '${activite_code}', '', 'all')"
                                data-toggle="modal" data-target=".rapport_40_modal">
                                ${ vm.convertToNumber(vm.total_visites[activite_code]) }
                            </a>
                        </td>
                        <td class="text-center"style="background: aquamarine;">
                            <a ng-click="vm.detailsVisitesRap40('', '${activite_code}', '', 'out_tournee')"
                                data-toggle="modal" data-target=".rapport_40_modal">
                            ${ vm.convertToNumber(vm.visites_in_tournees[activite_code]) }
                            </a>
                        </td>
                        <td class="text-center"style="background: aquamarine;">
                            ${ vm.convertToNumber(vm.programme_non_visite[activite_code]) }
                        </td>
                        <td class="text-center"style="background: aquamarine;">
                            <a ng-click="vm.detailsVisitesRap40('', '${activite_code}', '', 'out_tournee')"
                                data-toggle="modal" data-target=".rapport_40_modal">
                            ${ vm.convertToNumber(vm.visites_out_tournees[activite_code]) }
                            </a>
                        </td>
                        <td class="text-center"style="background: aquamarine;">
                            <a ng-click="vm.detailsNVisiteNPlanifie('', '${activite_code}', '')"
                                    data-toggle="modal" data-target=".rapport_40_modal">
                            ${ vm.convertToNumber(vm.non_visite_non_planifie[activite_code]) }
                            </a>
                        </td>
                        <td class="text-center"style="background: aquamarine;">
                            ${ (vm.convertToNumber(vm.total_visites[activite_code]) *100 / vm.convertToNumber(vm.nbr_cls[activite_code])).toFixed(0) } %
                        </td>
                        <td class="text-center"style="border-right: 2px solid #000;background: aquamarine;">
                        ${vm.convertToNumber(vm.total_tournees[activite_code]) ? (vm.convertToNumber(vm.visites_in_tournees[activite_code]) * 100 / vm.convertToNumber(vm.total_tournees[activite_code])).toFixed(0) : 0} %
                        </td>
                    </tr>`;
            })
        //}

        console.log('fin');
        //console.log(tbodys);

        vm.tbodys = vm.trustHtml(tbodys);
        //vm.tbodys = $compile(vm.tbodys)($scope);
        //console.log(vm.tbodys);
        vm.loading_request = false;

    }



    vm.getClientByZoneActivite = (zone_code, activite_code) => {
        WS.post('rapports/getClientByZoneActivite', {
            zone_code, activite_code , 
            typeclients_codes : vm.filterdate.typeclients_codes,
            specialites : vm.filterdate.specialites
        }).then((response) => {
            vm.clients = response.data.clients;
            vm.entetes = response.data.entetes;
            vm.credits_cls = response.data.credits;
            vm.center_cls = response.data.center;


            //maps 
                    $('#mapsContainer').empty();
                    $('#mapsContainer').html('<div style="width: 100%; height: 75vh" id="mapsHistoryContainer"></div>');

                    const latitude = vm.center_cls.latitude;
                    const longitude = vm.center_cls.longitude;
                    vm.zoomRouting = 11;
                    var mymap = L.map('mapsHistoryContainer').setView([latitude, longitude], vm.zoomRouting);
                    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
                        foo: 'bar',
                        attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
                    }).addTo(mymap);
                    var redMarker = L.ExtraMarkers.icon({
                        icon: 'fa-home',
                        markerColor:  'blue',
                        shape: 'circle', //'circle', 'square', 'star', or 'penta'
                        prefix: 'fa'
                    });

                    _.each(vm.clients, element => {
                        var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);
                        var info = $compile(`
                                                 <div id="bodyContent" style="width=300px;">
                                                      <p class="text-center">
                                                        <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                            Modifier
                                                        </a>
                                                      </p>
                                                      <b>Client</b> : ${element.client}</a></br>
                                                      <b>Magasin</b> : ${element.magasin}</a></br>
                                                      <b>Code</b> : ${element.client_code}</a></br>
                                                      <b>Code_a_barre</b> : ${element.code_a_barre}</br>
                                                      <b>Route</b> : ${element.routing_code}</br>
                                                      <b>Mobile</b> : ${element.mobile}</br>
                                                    </div>
                                                `)($scope);
                        var popup = L.popup({
                            maxWidth: 600,
                            minWidth: 200
                        }).setContent(info[0])
                        marker.bindPopup(popup);
                    })
                    

            $scope.$apply();
        },
        (error) => {
            console.error(error);
        })
    }


    vm.detailsTourneesPlanifie = (zone_code, activite_code, user_code) => {
        WS.post('rapports/detailsTourneesPlanifie', {
            zone_code, activite_code, user_code, 
            date_debut:vm.filterdate.date_debut, 
            date_fin:vm.filterdate.date_fin, 
            typeclients_codes:vm.filterdate.typeclients_codes, 
            specialites:vm.filterdate.specialites, 
            
        }).then((response) => {
            vm.clients = response.data.clients;
            vm.center_cls = response.data.center;

            //maps 
            $('#mapsContainer').empty();
            $('#mapsContainer').html('<div style="width: 100%; height: 75vh" id="mapsHistoryContainer"></div>');

            const latitude = vm.center_cls.latitude;
            const longitude = vm.center_cls.longitude;
            vm.zoomRouting = 11;
            var mymap = L.map('mapsHistoryContainer').setView([latitude, longitude], vm.zoomRouting);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
                foo: 'bar',
                attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
            }).addTo(mymap);
            var redMarker = L.ExtraMarkers.icon({
                icon: 'fa-home',
                markerColor:  'blue',
                shape: 'circle', //'circle', 'square', 'star', or 'penta'
                prefix: 'fa'
            });

            _.each(vm.clients, element => {
                var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);
                var info = $compile(`
                                            <div id="bodyContent" style="width=300px;">
                                                <p class="text-center">
                                                <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                    Modifier
                                                </a>
                                                </p>
                                                <b>Client</b> : ${element.client}</a></br>
                                                <b>Magasin</b> : ${element.magasin}</a></br>
                                                <b>Code</b> : ${element.client_code}</a></br>
                                                <b>Code_a_barre</b> : ${element.code_a_barre}</br>
                                                <b>Route</b> : ${element.routing_code}</br>
                                                <b>Mobile</b> : ${element.mobile}</br>
                                            </div>
                                        `)($scope);
                var popup = L.popup({
                    maxWidth: 600,
                    minWidth: 200
                }).setContent(info[0])
                marker.bindPopup(popup);
            })
                    

            $scope.$apply();
        },
        (error) => {
            console.error(error);
        })
    }

    vm.detailsVisitesRap40 = (zone_code, activite_code, user_code, type) => {
        WS.post('rapports/detailsVisitesRap40', {
            zone_code, 
            activite_code, 
            user_code, 
            type, 
            date_debut:vm.filterdate.date_debut, 
            date_fin:vm.filterdate.date_fin,
            typeclients_codes:vm.filterdate.typeclients_codes,
            specialites:vm.filterdate.specialites

        }).then((response) => {
            vm.clients = response.data.clients;
            vm.center_cls = response.data.center;

            //maps 
            $('#mapsContainer').empty();
            $('#mapsContainer').html('<div style="width: 100%; height: 75vh" id="mapsHistoryContainer"></div>');

            const latitude = vm.center_cls.latitude;
            const longitude = vm.center_cls.longitude;
            vm.zoomRouting = 11;
            var mymap = L.map('mapsHistoryContainer').setView([latitude, longitude], vm.zoomRouting);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
                foo: 'bar',
                attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
            }).addTo(mymap);
            var redMarker = L.ExtraMarkers.icon({
                icon: 'fa-home',
                markerColor:  'blue',
                shape: 'circle', //'circle', 'square', 'star', or 'penta'
                prefix: 'fa'
            });

            _.each(vm.clients, element => {
                var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);
                var info = $compile(`
                                            <div id="bodyContent" style="width=300px;">
                                                <p class="text-center">
                                                <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                    Modifier
                                                </a>
                                                </p>
                                                <b>Client</b> : ${element.client}</a></br>
                                                <b>Magasin</b> : ${element.magasin}</a></br>
                                                <b>Code</b> : ${element.client_code}</a></br>
                                                <b>Code_a_barre</b> : ${element.code_a_barre}</br>
                                                <b>Route</b> : ${element.routing_code}</br>
                                                <b>Mobile</b> : ${element.mobile}</br>
                                            </div>
                                        `)($scope);
                var popup = L.popup({
                    maxWidth: 600,
                    minWidth: 200
                }).setContent(info[0])
                marker.bindPopup(popup);
            })
                    

            $scope.$apply();
        },
        (error) => {
            console.error(error);
        })
    }
    
    vm.detailsPlanifieNonVisite = (zone_code, activite_code, user_code) => {
        WS.post('rapports/detailsPlanifieNonVisite', {
            zone_code, activite_code, user_code, 
            date_debut:vm.filterdate.date_debut, 
            date_fin:vm.filterdate.date_fin,
            typeclients_codes:vm.filterdate.typeclients_codes,
            specialites:vm.filterdate.specialites
        }).then((response) => {
            vm.clients = response.data.clients;
            vm.center_cls = response.data.center;

            //maps 
            $('#mapsContainer').empty();
            $('#mapsContainer').html('<div style="width: 100%; height: 75vh" id="mapsHistoryContainer"></div>');

            const latitude = vm.center_cls.latitude;
            const longitude = vm.center_cls.longitude;
            vm.zoomRouting = 11;
            var mymap = L.map('mapsHistoryContainer').setView([latitude, longitude], vm.zoomRouting);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
                foo: 'bar',
                attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
            }).addTo(mymap);
            var redMarker = L.ExtraMarkers.icon({
                icon: 'fa-home',
                markerColor:  'blue',
                shape: 'circle', //'circle', 'square', 'star', or 'penta'
                prefix: 'fa'
            });

            _.each(vm.clients, element => {
                var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);
                var info = $compile(`
                                            <div id="bodyContent" style="width=300px;">
                                                <p class="text-center">
                                                <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                    Modifier
                                                </a>
                                                </p>
                                                <b>Client</b> : ${element.client}</a></br>
                                                <b>Magasin</b> : ${element.magasin}</a></br>
                                                <b>Code</b> : ${element.client_code}</a></br>
                                                <b>Code_a_barre</b> : ${element.code_a_barre}</br>
                                                <b>Route</b> : ${element.routing_code}</br>
                                                <b>Mobile</b> : ${element.mobile}</br>
                                            </div>
                                        `)($scope);
                var popup = L.popup({
                    maxWidth: 600,
                    minWidth: 200
                }).setContent(info[0])
                marker.bindPopup(popup);
            })
                    

            $scope.$apply();
        },
        (error) => {
            console.error(error);
        })
    }
    

    vm.detailsNVisiteNPlanifie = (zone_code, activite_code, user_code) => {
        WS.post('rapports/detailsNVisiteNPlanifie', {
            region_code: vm.filterdate.region_code,
            zone_code, 
            activite_code, 
            user_code, 
            date_debut:vm.filterdate.date_debut, 
            date_fin:vm.filterdate.date_fin,
            typeclients_codes:vm.filterdate.typeclients_codes,
            specialites: vm.filterdate.specialites

        }).then((response) => {
            vm.clients = response.data.clients;
            vm.center_cls = response.data.center;

            //maps 
            $('#mapsContainer').empty();
            $('#mapsContainer').html('<div style="width: 100%; height: 75vh" id="mapsHistoryContainer"></div>');

            const latitude = vm.center_cls.latitude;
            const longitude = vm.center_cls.longitude;
            vm.zoomRouting = 11;
            var mymap = L.map('mapsHistoryContainer').setView([latitude, longitude], vm.zoomRouting);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
                foo: 'bar',
                attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
            }).addTo(mymap);
            var redMarker = L.ExtraMarkers.icon({
                icon: 'fa-home',
                markerColor:  'blue',
                shape: 'circle', //'circle', 'square', 'star', or 'penta'
                prefix: 'fa'
            });

            _.each(vm.clients, element => {
                var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);
                var info = $compile(`
                                            <div id="bodyContent" style="width=300px;">
                                                <p class="text-center">
                                                <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                    Modifier
                                                </a>
                                                </p>
                                                <b>Client</b> : ${element.client}</a></br>
                                                <b>Magasin</b> : ${element.magasin}</a></br>
                                                <b>Code</b> : ${element.client_code}</a></br>
                                                <b>Code_a_barre</b> : ${element.code_a_barre}</br>
                                                <b>Route</b> : ${element.routing_code}</br>
                                                <b>Mobile</b> : ${element.mobile}</br>
                                            </div>
                                        `)($scope);
                var popup = L.popup({
                    maxWidth: 600,
                    minWidth: 200
                }).setContent(info[0])
                marker.bindPopup(popup);
            })
                    

            $scope.$apply();
        },
        (error) => {
            console.error(error);
        })
    }

    vm.getSuiviEchantillon = function (date_debut, date_fin) {
        console.log(date_debut);
        WS.get('rapports/suiviEchantillon' + '/' + date_debut + '/' + date_fin).then(
            function (response) {
                var count = response.data.length;

                var filename = 'Rapport-Suivi Echantillons-de '+date_debut+' à '+date_fin;
                
                var file = {
                    sheetid: 'Rapport-Suivi Echantillons-' + moment().format("DD-MM-YYYY HH:mm:ss"),
                    headers: true,
                    caption: {
                        title: 'Rapport-Suivi Echantillons-' + moment().format("DD-MM-YYYY HH:mm:ss"),
                        style: 'font-size: 50px; color:blue;'
                    },
                    column: {
                        style: 'font-size:20px;background:#d1d1d1;'
                    },
                    columns: [

                        { columnid: 'commercial', title: 'Commercial' },
                        { columnid: 'client_code', title: 'Code prospect' },
                        { columnid: 'prospect', title: 'Prospect' },
                        { columnid: 'code', title: 'Code article' },
                        { columnid: 'libelle', title: 'Produit' },
                        { columnid: 'echantillon', title: 'echantillon ' }

                    ],
                    row: {
                        style: function (sheet, row, rowidx) {
                            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                        }
                    }
                };
               
                alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
            },
            function (error) {
                console.log(error);
            }
        );
    };

    vm.getHaversine = (latitude_client, longitude_client, latitude, longitude) => {
        const client = { latitude: parseFloat(latitude_client), longitude: parseFloat(longitude_client) }
        const visite = { latitude: parseFloat(latitude), longitude: parseFloat(longitude) }

        if(_.isNaN(client.latitude) || _.isNaN(client.latitude)){
            return 'PCI'
        }
        else if(_.isNaN(visite.latitude) || _.isNaN(visite.latitude)){
            return 'PVI'
        }else {
            var position = haversine(client, visite)
            if(position > 1000){
                position = position / 1000
                position =  position.toFixed(3) + ' Km';
            }else{
                position =  position.toFixed(3) + ' m';
  
            }
            return position;
        }
         //return distance en métre
    }


    /**
     * double scroll table
     */
    $("document").ready(function(){
        $("#scroll1 div").width($(".table_scroll").width());
        $("#scroll1").on("scroll", function(){
            $("#scroll2").scrollLeft($(this).scrollLeft());
        });
        $("#scroll2").on("scroll", function(){
            $("#scroll1").scrollLeft($(this).scrollLeft());
        });
    });

    


    vm.getAssortimentsRap41 = () => {
        var data = {
            //client
            categoriecommercial_code: vm.categoriecommercial_code,
            activite_code: vm.activite_code,
            zone_code: vm.zone_code,
            region_code: vm.region_code,
            gouvernorat_code: vm.gouvernorat_code,
            delegation_code: vm.delegation_code,
            routing_code: vm.routing_code,
            user_code: vm.user_code,
            specialite: vm.specialite,
            type_client : vm.type_client,
            //produit
            gamme_code : vm.gamme_code,
            famille_code : vm.famille_code,
            sousfamille_code : vm.sousfamille_code,
            marque_code : vm.marque_code,
            //interval
            date_debut : vm.date_debut,
            date_fin : vm.date_fin
        };
        if(vm.date_debut && vm.date_fin){

            WS.getData('rapports/getAssortimentsRap41', data)
                .then(function (response) {
                    if (response.status == 200) {
                        vm.list_prods_assortiment = response.data.produits.assortiment;
                        vm.list_prods_prix = response.data.produits.prix;
                        vm.list_prods_comparaison = response.data.produits.comparaison;
                        vm.list_prods_stock = response.data.produits.stock;
                        vm.list_clients = response.data.clients;
                        vm.list_relevet_prix = response.data.relevet_prix;
                        vm.qualite_affiche = response.data.qualite_affiche;
                        // console.log(vm.list_prods)
                        // console.log(vm.list_clients)

                        vm.qualites = response.data.qualites;
                        vm.qualites_details = response.data.qualites_details;
                        vm.visibilites = response.data.visibilites;
                        vm.visibilites_details = response.data.visibilites_details;
                        // vm.list_gammes = _.groupBy(vm.list_prods, 'gamme');
                        // console.log(vm.list_gammes)

                        //vm.totalprods = Object.keys(vm.list_prods).length;
                       // console.log(vm.totalprods)
                        $scope.$apply();

                        $("document").ready(function(){
                            $("#scroll1_41 div").width($(".table_scroll_41").width());
                            $("#scroll2_41").width($(".table_scroll_41").width());
                            $("#scroll1_41").on("scroll", function(){
                                $("#scroll2_41").scrollLeft($(this).scrollLeft());
                            });
                            $("#scroll2_41").on("scroll", function(){
                                $("#scroll1_41").scrollLeft($(this).scrollLeft());
                            });
                        });
                    } else { }
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }else{
            swal(
                'Oups',
                'Veuillez bien choisir date début et date fin',
                'warning'
            )
        }

    }



    /**
     * Rapport 42 Liste clients by users
     */


    vm.rapport_42_listClient = () => {
        WS.getData('rapports/rapport_42_listClient', vm.filterdate)
                .then(function (response) {
                    vm.last7Month = response.data.last7Month;
                    vm.lastDate = response.data.lastDate;
                    vm.yearMonth = response.data.yearMonth;

                    vm.creditMonth = response.data.creditMonth;
                    vm.recouvrementMonth = response.data.recouvrementMonth;
                    vm.creditAll = response.data.creditAll;
                    vm.venteMois = response.data.venteMois;
                    vm.vente7Month = response.data.vente7Month;
                    vm.dernier_facture = response.data.dernier_facture;
                    vm.dernier_recouvrement = response.data.dernier_recouvrement;

                    vm.vente_mois = response.data.vente_mois;
                    vm.credit_mois = response.data.credit_mois;
                    vm.recouvrement_mois = response.data.recouvrement_mois;
                    vm.correction_mois = response.data.correction_mois;
                    vm.sommeMoyenneVente = response.data.sommeMoyenneVente;
                    vm.sommeCreditMonth = response.data.sommeCreditMonth;
                     vm.creditAllForCommercial = response.data.creditAllForCommercial;
                      vm.nbr_recouvrement_mois = response.data.nbr_recouvrement_mois;
                    

                    $scope.$apply();

                })
                .then(null, function (error) {
                    console.log(error);
                });
    };

    $(document).ready(function () {

        $(".months_rapport42").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.filterdate.date = date;
                $scope.$apply();
                //console.log(vm.month);
            }
        });
    });



    vm.gratuitesAccessoires_R44 = ()  => {

        vm.th_libelles_types = [
            {  code : 'cadeaux', libelle : 'Cadeaux' },
            { code : 'retour', libelle : 'Retour' },
            { code : 'retourC', libelle : 'Retour Client' },
            { code : 'vente', libelle : 'Vente' },
            { code : 'gratuite', libelle : 'Gratuite' },
            { code : 'dlcC', libelle : 'Dlc Client' },
            { code : 'commande', libelle : 'Commande' }
        ];

        vm.td_libelles_types = [
            { code : 'cadeaux', libelle : 'Cadeaux', type : 'quantite' },
            { code : 'cadeaux', libelle : 'Cadeaux', type : 'chiffre' },
            { code : 'retour', libelle : 'Retour', type : 'quantite' },
            { code : 'retour', libelle : 'Retour', type : 'chiffre' },
            { code : 'retourC', libelle : 'Retour Client', type : 'quantite' },
            { code : 'retourC', libelle : 'Retour Client', type : 'chiffre' },
            { code : 'vente', libelle : 'Vente', type : 'quantite' },
            { code : 'vente', libelle : 'Vente', type : 'chiffre' },
            { code : 'gratuite', libelle : 'Gratuite', type : 'quantite' },
            { code : 'gratuite', libelle : 'Gratuite', type : 'chiffre' },
            { code : 'dlcC', libelle : 'Dlc Client', type : 'quantite' },
            { code : 'dlcC', libelle : 'Dlc Client', type : 'chiffre' },
            { code : 'commande', libelle : 'Commande', type : 'quantite' },
            { code : 'commande', libelle : 'Commande', type : 'chiffre' }
        ];

        WS.getData('rapports/gratuitesAccessoires_R44', vm.search44)
                .then(function (response) {
                    console.log(response.data);
                    vm.resultat_44 = response.data.collection;
                    vm.req_44 = response.data.results;

                    //console.log('vm.req_44', vm.req_44);
                    var grpbytype = _.groupBy(vm.req_44, function(value){ return value.type; });

                    vm.total_44 = _.mapObject(grpbytype, function(value,key){ return {
                                        //type : key,
                                        chiffre : _.reduce(value, function(memo, num){ return memo + num.chiffre; }, 0),
                                        quantite : _.reduce(value, function(memo, num){ return memo + num.quantite; }, 0)
                                    } });
                     //console.log('total_44', vm.total_44);

                    // vm.total_44 = _.groupBy(total_44, function(value){ return value.type; });

                    // console.log('vm.total_44', vm.total_44);


                    $scope.$apply();
                })
                .then(null, function (error) {
                    console.log(error);
                });
    }
}
export default {
    name: 'rapportController',
    fn: rapportController
};


